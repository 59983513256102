// @flow

import type { ComponentType, Node } from 'react'
import * as React from 'react'
import Modal from 'react-modal'
import { useTheme } from 'emotion-theming'
import styled from '@emotion/styled'

import { Icon } from '@toggl/ui'
import { media } from '@toggl/style'

type ModalVideoProps = {
  children: Node,
  Content?: ComponentType<any>,
  placeholder?: string | React.Node,
  skewed?: boolean,
}

export default function ModalVideo({
  children,
  Content,
  placeholder,
  skewed,
  ...props
}: ModalVideoProps) {
  const theme = useTheme()
  const [isModalOpen, setModalOpen] = React.useState(false)

  function openModal() {
    setModalOpen(true)
  }

  function closeModal() {
    setModalOpen(false)
  }

  return (
    <Root {...props}>
      <div>
        {skewed ? (
          <SkewedImgWrapper onClick={openModal}>
            {typeof placeholder === 'string' ? (
              <SkewedImg src={placeholder} />
            ) : (
              placeholder
            )}
            <Icon.Play />
          </SkewedImgWrapper>
        ) : (
          <ImgWrapper>
            {typeof placeholder === 'string' ? (
              <Img src={placeholder} />
            ) : (
              placeholder
            )}
            <Icon.Play color={theme.primary} arrowColor={theme.accent} />
          </ImgWrapper>
        )}

        {Content !== undefined && <Content />}
      </div>

      <Modal
        style={customStyles}
        isOpen={isModalOpen}
        onRequestClose={closeModal}
      >
        {children}
      </Modal>
    </Root>
  )
}

const Root: ComponentType<any> = styled.div`
  video,
  img {
    max-width: 100%;
  }
`

export const Img: ComponentType<any> = styled.img`
  width: 260px;

  ${media.mq[1]} {
    width: 388px;
  }
`

const ImgWrapper: ComponentType<any> = styled.div`
  position: relative;
  cursor: pointer;

  svg {
    fill: ${(p) => p.theme.primary};
    position: absolute;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
  }

  ${media.mq[1]} {
    svg {
      width: 62px;
      height: 62px;
      top: calc(50% - 31px);
      left: calc(50% - 31px);
    }
  }
`

const customStyles = {
  /* stylelint-disable selector-type-no-unknown */
  overlay: {
    background: 'transparent',
    overflow: 'hidden',
    height: '100%',
    zIndex: '100',
  },
  content: {
    position: 'absolute',
    top: '120px',
    left: '40px',
    right: '40px',
    bottom: '100px',
    padding: '0',
    border: 'none',
    background: 'transparent',
    overflow: 'hidden',
  },
  /* stylelint-enable */
}

export const SkewedImg: ComponentType<any> = styled.img``

const SkewedImgWrapper: ComponentType<any> = styled.div`
  position: relative;
  cursor: pointer;

  svg {
    width: 64px;
    height: 64px;
    position: absolute;
    top: calc(50%);
    left: calc(50% - 32px);
    z-index: 110;
  }

  img {
    max-width: 100%;
    transform: rotate(10deg);
    margin-top: 50px;
    position: relative;
    z-index: 105;

    ${media.mq[1]} {
      max-width: 450px;
    }
  }

  ${media.mq[1]} {
    margin-top: 45px;

    svg {
      width: 95px;
      height: 95px;
      top: calc(50% - 47px);
      left: calc(50% - 47px);
    }
  }
`
