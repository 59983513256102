// @flow

import * as React from 'react'

export const Hand = () => (
  <svg viewBox="0 0 27 47" version="1.1">
    <g
      id="Desktop-Track"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Toggl_Business_page"
        transform="translate(-354.000000, -1772.000000)"
        fill="#2C1338"
        fillRule="nonzero"
      >
        <g id="USP" transform="translate(354.000000, 1410.000000)">
          <g id="USPs" transform="translate(0.000000, 129.000000)">
            <g id="Group" transform="translate(0.000000, 233.000000)">
              <g transform="translate(11.711538, 0.000000)" id="Path">
                <path d="M3.89078846,16.6153846 C3.59315385,16.6153846 3.35232692,16.4089615 3.35232692,16.1538462 L3.35232692,1.84661538 C3.35232692,1.5915 3.59315385,1.38507692 3.89078846,1.38507692 C4.18828846,1.38507692 4.42925,1.5915 4.42925,1.84661538 L4.42925,16.1538462 C4.42911538,16.4089615 4.18828846,16.6153846 3.89078846,16.6153846 Z"></path>
                <path d="M0.660019231,16.6153846 C0.362384615,16.6153846 0.121557692,16.4089615 0.121557692,16.1538462 L0.121557692,1.84661538 C0.121557692,1.5915 0.362384615,1.38507692 0.660019231,1.38507692 C0.957519231,1.38507692 1.19848077,1.5915 1.19848077,1.84661538 L1.19848077,16.1538462 C1.19848077,16.4089615 0.957653846,16.6153846 0.660019231,16.6153846 Z"></path>
                <path d="M3.89078846,2.30815385 C3.59315385,2.30815385 3.35232692,2.10173077 3.35232692,1.84661538 C3.35232692,1.33776923 2.86905769,0.923538462 2.27540385,0.923538462 C1.68175,0.923538462 1.19848077,1.33776923 1.19848077,1.84661538 C1.19848077,2.10173077 0.957653846,2.30815385 0.660019231,2.30815385 C0.362384615,2.30815385 0.121557692,2.10173077 0.121557692,1.84661538 C0.121557692,0.828461538 1.08742308,0.000461538462 2.27540385,0.000461538462 C3.46338462,0.000461538462 4.42925,0.828346154 4.42925,1.84661538 C4.42911538,2.10173077 4.18828846,2.30815385 3.89078846,2.30815385 Z"></path>
              </g>
              <path
                d="M8.71123077,30.2771538 C8.53407692,30.2771538 8.36055769,30.2023846 8.25744231,30.0643846 L0.377461538,19.545 C0.0177692308,19.0122692 -0.0741730769,18.4137692 0.105134615,17.8405385 C0.284442308,17.2673077 0.713057692,16.7885769 1.3125,16.4915769 C2.55082692,15.879 4.13875,16.2445385 4.85288462,17.3051538 L8.49315385,21.4129615 C8.673,21.6167308 8.62615385,21.9058846 8.3895,22.0600385 C8.15176923,22.2132692 7.81375,22.1735769 7.63498077,21.9716538 L3.95728846,17.8144615 C3.50296154,17.1469615 2.57451923,16.9365 1.85042308,17.2911923 C1.50069231,17.4647308 1.25044231,17.7441923 1.14530769,18.0795 C1.04071154,18.4148077 1.09428846,18.7646538 1.29675,19.0637308 L9.16407692,29.5669615 C9.32440385,29.7814615 9.25184615,30.0663462 9.00159615,30.2042308 C8.91167308,30.2537308 8.81071154,30.2771538 8.71123077,30.2771538 Z"
                id="Path"
              ></path>
              <path
                d="M10.2178462,16.6153846 C9.92021154,16.6153846 9.67938462,16.4089615 9.67938462,16.1538462 L9.67938462,3.69265385 C9.67938462,3.43753846 9.92021154,3.23111538 10.2178462,3.23111538 C10.5154808,3.23111538 10.7563077,3.43753846 10.7563077,3.69265385 L10.7563077,16.1538462 C10.7563077,16.4089615 10.5154808,16.6153846 10.2178462,16.6153846 Z"
                id="Path"
              ></path>
              <path
                d="M6.98721154,18.4615385 C6.68957692,18.4615385 6.44875,18.2551154 6.44875,18 L6.44875,3.69265385 C6.44875,3.43753846 6.68957692,3.23111538 6.98721154,3.23111538 C7.28471154,3.23111538 7.52567308,3.43753846 7.52567308,3.69265385 L7.52567308,18 C7.52553846,18.2551154 7.28471154,18.4615385 6.98721154,18.4615385 Z"
                id="Path"
              ></path>
              <path
                d="M20.9872115,33.3763846 C20.7910769,33.3763846 20.6023462,33.2844231 20.5071731,33.1248462 C20.3720192,32.8976538 20.4771538,32.6191154 20.7416731,32.5037308 C23.8819808,31.1299615 25.8326923,28.3968462 25.8326923,25.3726154 C25.8326923,25.1175 26.0735192,24.9110769 26.3711538,24.9110769 C26.6687885,24.9110769 26.9096154,25.1175 26.9096154,25.3726154 C26.9096154,28.7456538 24.7336923,31.7933077 21.2316731,33.3258462 C21.1533269,33.3601154 21.0691923,33.3763846 20.9872115,33.3763846 Z"
                id="Path"
              ></path>
              <path
                d="M13.4526538,33.8368846 C13.3905962,33.8368846 13.328,33.8278846 13.2664808,33.8089615 C11.0990385,33.1239231 9.31228846,31.7825769 8.23428846,30.0320769 C8.0955,29.8067308 8.19646154,29.5272692 8.45936538,29.4083077 C8.72388462,29.2893462 9.04830769,29.3758846 9.18709615,29.6012308 C10.1404423,31.1499231 11.7216346,32.3371154 13.6386923,32.9419615 C13.9178846,33.0303462 14.0604423,33.2952692 13.9578654,33.5351538 C13.8775,33.7215 13.6719423,33.8368846 13.4526538,33.8368846 Z"
                id="Path"
              ></path>
              <path
                d="M10.2178462,4.15419231 C9.92021154,4.15419231 9.67938462,3.94776923 9.67938462,3.69265385 C9.67938462,3.18380769 9.19611538,2.76957692 8.60246154,2.76957692 C8.00880769,2.76957692 7.52553846,3.18380769 7.52553846,3.69265385 C7.52553846,3.94776923 7.28471154,4.15419231 6.98707692,4.15419231 C6.68944231,4.15419231 6.44861538,3.94776923 6.44861538,3.69265385 C6.44861538,2.6745 7.41448077,1.8465 8.60232692,1.8465 C9.79017308,1.8465 10.7561731,2.6745 10.7561731,3.69265385 C10.7563077,3.94776923 10.5154808,4.15419231 10.2178462,4.15419231 Z"
                id="Path"
              ></path>
              <path
                d="M20.9866731,16.6153846 C20.6890385,16.6153846 20.4482115,16.4089615 20.4482115,16.1538462 L20.4482115,3.69265385 C20.4482115,3.43753846 20.6890385,3.23111538 20.9866731,3.23111538 C21.2841731,3.23111538 21.5251346,3.43753846 21.5251346,3.69265385 L21.5251346,16.1538462 C21.5251346,16.4089615 21.2843077,16.6153846 20.9866731,16.6153846 Z"
                id="Path"
              ></path>
              <path
                d="M17.7560385,16.6153846 C17.4584038,16.6153846 17.2175769,16.4089615 17.2175769,16.1538462 L17.2175769,3.69265385 C17.2175769,3.43753846 17.4584038,3.23111538 17.7560385,3.23111538 C18.0536731,3.23111538 18.2945,3.43753846 18.2945,3.69265385 L18.2945,16.1538462 C18.2945,16.4089615 18.0536731,16.6153846 17.7560385,16.6153846 Z"
                id="Path"
              ></path>
              <path
                d="M20.9866731,4.15419231 C20.6890385,4.15419231 20.4482115,3.94776923 20.4482115,3.69265385 C20.4482115,3.18380769 19.9649423,2.76957692 19.3712885,2.76957692 C18.7776346,2.76957692 18.2943654,3.18380769 18.2943654,3.69265385 C18.2943654,3.94776923 18.0535385,4.15419231 17.7559038,4.15419231 C17.4582692,4.15419231 17.2174423,3.94776923 17.2174423,3.69265385 C17.2174423,2.6745 18.1834423,1.8465 19.3712885,1.8465 C20.5591346,1.8465 21.5250001,2.6745 21.5250001,3.69265385 C21.5251346,3.94776923 21.2843077,4.15419231 20.9866731,4.15419231 Z"
                id="Path"
              ></path>
              <path
                d="M26.3711538,25.8341538 C26.0735192,25.8341538 25.8326923,25.6277308 25.8326923,25.3726154 L25.8326923,6.92330769 C25.8326923,6.66830769 26.0735192,6.46176923 26.3711538,6.46176923 C26.6687885,6.46176923 26.9096154,6.66819231 26.9096154,6.92330769 L26.9096154,25.3726154 C26.9096154,25.6277308 26.6687885,25.8341538 26.3711538,25.8341538 Z"
                id="Path"
              ></path>
              <path
                d="M23.1405192,16.6153846 C22.8428846,16.6153846 22.6020577,16.4089615 22.6020577,16.1538462 L22.6020577,6.92330769 C22.6020577,6.66830769 22.8428846,6.46176923 23.1405192,6.46176923 C23.4381538,6.46176923 23.6789808,6.66819231 23.6789808,6.92330769 L23.6789808,16.1538462 C23.6789808,16.4089615 23.4381538,16.6153846 23.1405192,16.6153846 Z"
                id="Path"
              ></path>
              <path
                d="M26.3711538,7.38484615 C26.0735192,7.38484615 25.8326923,7.17842308 25.8326923,6.92330769 C25.8326923,6.41446154 25.3494231,6.00023077 24.7557692,6.00023077 C24.1621154,6.00023077 23.6788462,6.41446154 23.6788462,6.92330769 C23.6788462,7.17842308 23.4380192,7.38484615 23.1403846,7.38484615 C22.84275,7.38484615 22.6019231,7.17842308 22.6019231,6.92330769 C22.6019231,5.90515385 23.5679231,5.07715385 24.7557692,5.07715385 C25.9436154,5.07715385 26.9096154,5.90515385 26.9096154,6.92330769 C26.9096154,7.17842308 26.6687885,7.38484615 26.3711538,7.38484615 Z"
                id="Path"
              ></path>
              <path
                d="M12.3715577,35.9994231 C12.0739231,35.9994231 11.8330962,35.793 11.8330962,35.5378846 L11.8330962,32.9607692 C11.8330962,32.7056538 12.0739231,32.4992308 12.3715577,32.4992308 C12.6690577,32.4992308 12.9100192,32.7056538 12.9100192,32.9607692 L12.9100192,35.5378846 C12.9100192,35.793 12.6691923,35.9994231 12.3715577,35.9994231 Z"
                id="Path"
              ></path>
              <path
                d="M22.0635962,35.9994231 C21.7659615,35.9994231 21.5251346,35.793 21.5251346,35.5378846 L21.5251346,32.3667692 C21.5251346,32.1116538 21.7659615,31.9052308 22.0635962,31.9052308 C22.3612308,31.9052308 22.6020577,32.1116538 22.6020577,32.3667692 L22.6020577,35.5378846 C22.6020577,35.793 22.3612308,35.9994231 22.0635962,35.9994231 Z"
                id="Path"
              ></path>
              <path
                d="M24.2174423,40.6146923 L10.2178462,40.6146923 C9.92021154,40.6146923 9.67938462,40.4082692 9.67938462,40.1531538 C9.67938462,39.8980385 9.92021154,39.6916154 10.2178462,39.6916154 L24.2174423,39.6916154 C24.5149423,39.6916154 24.7559038,39.8980385 24.7559038,40.1531538 C24.7559038,40.4082692 24.5149423,40.6146923 24.2174423,40.6146923 Z"
                id="Path"
              ></path>
              <path
                d="M24.2174423,46.1529231 C23.9198077,46.1529231 23.6789808,45.9465 23.6789808,45.6913846 L23.6789808,40.1531538 C23.6789808,39.8980385 23.9198077,39.6916154 24.2174423,39.6916154 C24.5149423,39.6916154 24.7559038,39.8980385 24.7559038,40.1531538 L24.7559038,45.6913846 C24.7557692,45.9465 24.5149423,46.1529231 24.2174423,46.1529231 Z"
                id="Path"
              ></path>
              <path
                d="M10.2178462,46.1529231 C9.92021154,46.1529231 9.67938462,45.9465 9.67938462,45.6913846 L9.67938462,40.1531538 C9.67938462,39.8980385 9.92021154,39.6916154 10.2178462,39.6916154 C10.5154808,39.6916154 10.7563077,39.8980385 10.7563077,40.1531538 L10.7563077,45.6913846 C10.7563077,45.9465 10.5154808,46.1529231 10.2178462,46.1529231 Z"
                id="Path"
              ></path>
              <path
                d="M11.2947692,39.6916154 C10.9971346,39.6916154 10.7563077,39.4851923 10.7563077,39.2300769 L10.7563077,36.4608462 C10.7563077,36.2058462 10.9971346,35.9994231 11.2947692,35.9994231 C11.5922692,35.9994231 11.8332308,36.2058462 11.8332308,36.4608462 L11.8332308,39.2300769 C11.8330962,39.4851923 11.5922692,39.6916154 11.2947692,39.6916154 Z"
                id="Path"
              ></path>
              <path
                d="M23.1405192,36.9223846 L11.2947692,36.9223846 C10.9971346,36.9223846 10.7563077,36.7159615 10.7563077,36.4608462 C10.7563077,36.2058462 10.9971346,35.9994231 11.2947692,35.9994231 L23.1405192,35.9994231 C23.4381538,35.9994231 23.6789808,36.2058462 23.6789808,36.4608462 C23.6789808,36.7159615 23.4381538,36.9223846 23.1405192,36.9223846 Z"
                id="Path"
              ></path>
              <path
                d="M23.1405192,39.8836154 C22.8428846,39.8836154 22.6020577,39.6771923 22.6020577,39.4220769 L22.6020577,36.4608462 C22.6020577,36.2058462 22.8428846,35.9994231 23.1405192,35.9994231 C23.4381538,35.9994231 23.6789808,36.2058462 23.6789808,36.4608462 L23.6789808,39.4220769 C23.6789808,39.6771923 23.4381538,39.8836154 23.1405192,39.8836154 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
