// @flow
import { formatDistanceToNowStrict, parseISO } from 'date-fns'
import type { Plugin } from '@bugsnag/core'

// Adds uptime infromation to report metadata.
const uptime: (string | void) => Plugin = (
  since = new Date().toISOString()
) => ({
  name: '@toggl-uptime',
  load(client) {
    client.addOnError((event) => {
      event.addMetadata('uptime', {
        since,
        up: formatDistanceToNowStrict(parseISO(since)),
      })
    })
  },
})

export default uptime
