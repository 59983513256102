// @flow
import styled from '@emotion/styled'
import * as React from 'react'
import { transparentize } from 'polished'

import { colors, media } from '@toggl/style'
import { hooks, text } from '@toggl/ui'

type WrapperProps = {|
  +children: $ReadOnlyArray<React.Element<typeof Link>>,
  +className?: string,
|}

export function Wrapper({ children, className }: WrapperProps) {
  if (!hooks.useMediaQuery(media.mqbp[1])) {
    return null
  }

  return <WrapperRoot className={className}>{children}</WrapperRoot>
}

const WrapperRoot: React.ComponentType<any> = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  background-color: ${colors.almostWhite};
  position: sticky;
  top: 0;
  z-index: 999;
`

type LinkProps = {|
  +active?: boolean,
  +children: React.Node,
  +className?: string,
  +onClick: () => void,
|}

export function Link({ active, children, className, onClick }: LinkProps) {
  const onLinkClick = (e) => {
    e.preventDefault()
    onClick()
  }

  return (
    <LinkRoot active={active} className={className} onClick={onLinkClick}>
      <Title active={active}>{children}</Title>
    </LinkRoot>
  )
}

const LinkRoot: React.ComponentType<any> = styled.a`
  flex: 1 auto;
  cursor: pointer;

  background-color: ${({ active }) => (active ? colors.darkPurple : '')};
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: ${({ active }) => (active ? 'default' : 'pointer')};

  &:not(:last-child) {
    border-right: 1px solid ${transparentize(0.9, colors.fadedPurpleAlt)};
  }

  &:hover {
    div {
      ${({ active, theme }) =>
        active ? '' : `border-bottom: 1px solid ${theme.primary}`}
    }
  }
`

const Title: React.ComponentType<any> = styled.div`
  ${text.paragraph3};
  color: ${({ active }) => (active ? colors.almostWhite : colors.darkPurple)};
  padding-bottom: 4px;
`
