// @flow

import type { ComponentType } from 'react'
import * as React from 'react'
import styled from '@emotion/styled'

import { colors, media } from '@toggl/style'

import * as text from '../text'

type Props = {
  Logo: ComponentType<any>,
  Cta: ComponentType<any>,
  image?: string | React.Node,
  title: string,
  video?: React.Node,
}

export default function ProductAlternate({
  Logo,
  Cta,
  image,
  title,
  video,
}: Props) {
  return (
    <Root>
      {image && typeof image === 'string' ? <Image src={image} /> : image}
      {video && <VideoWrapper>{video}</VideoWrapper>}
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <Title>{title}</Title>
      <CtaWrapper>
        <Cta />
      </CtaWrapper>
    </Root>
  )
}

const VideoWrapper = styled.div`
  width: 160px;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${(p) => p.theme.primary};

  video {
    max-width: 100%;
  }
`

const Image = styled.img`
  height: 123px;
  ${media.mq[1]} {
    height: 150px;
  }
`

const Title = styled.h5`
  ${text.paragraph2};
  color: ${colors.beige};
  margin-top: 14px;
  margin-bottom: 32px;

  ${media.mq[1]} {
    ${text.paragraph2};
    margin-top: 30px;
    margin-bottom: 20px;
  }
`

const LogoWrapper = styled.div`
  height: 28px;
  margin-top: 40px;

  svg {
    height: 100%;
  }
`

const CtaWrapper = styled.div``

const Root: ComponentType<any> = styled.div`
  ${text.defaultText};
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 358px;
  img {
    max-width: 100%;
  }

  ${media.mq[1]} {
    max-width: 365px;
    min-width: 280px;
    min-height: 500px;
    margin: 0 40px;
  }
`
