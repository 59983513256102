// @flow

import * as React from 'react'
import Helmet from 'react-helmet'
import type { ComponentType } from 'react'
import { global } from '@toggl/style'
import { Global } from '@emotion/core'
import { MDXProvider } from '@mdx-js/react'
import LayoutRoot from 'components/LayoutRoot'

const IndexLayout: ComponentType<any> = ({
  title,
  description,
  keywords,
  children,
  ogImage,
}: {
  title?: string,
  description?: string,
  children: React.Node,
  keywords?: string,
  ogImage?: string,
}) => (
  <LayoutRoot>
    <Global styles={global} />
    <Helmet>
      <title itemProp="name" lang="en">
        {title}
      </title>
      <meta
        name="description"
        content={description || 'Get your team on Track'}
      />
      <meta name="keywords" content={keywords || 'time,tracking'} />
      <meta name="og:image" content={ogImage} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
    </Helmet>
    <MDXProvider>{children}</MDXProvider>
  </LayoutRoot>
)

export default IndexLayout
