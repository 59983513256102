// @flow

export const heading1 = '1'
export const heading2 = '1.143'
export const heading3 = '1.25'
export const heading4 = '1.25'
export const heading5 = '1.5'

export const paragraph1 = '1.5'
export const paragraph2 = '1.333'
export const paragraph3 = '1.28'
export const paragraph4 = '1.28'
