// @flow

import * as Icon from './icons'

const Logo = {
  hire: Icon.Hire,
  track: Icon.Track,
  plan: Icon.Plan,
}

export default Logo
