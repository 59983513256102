// @flow
import { transparentize } from 'polished'
import * as React from 'react'
import styled from '@emotion/styled'

import { heading4, paragraph4 } from '../text'

type TableProps = {|
  +children: React.Node[],
|}

export function Table({ children }: TableProps) {
  return (
    <Wrapper>
      <Header>{'Table of content'}</Header>
      <ContentList>{children}</ContentList>
    </Wrapper>
  )
}

const Wrapper: React.ComponentType<any> = styled.div`
  width: 320px;
  min-height: 450px;
  background-color: ${({ theme }) => theme.background};
`

const Header: React.ComponentType<any> = styled.div`
  ${heading4};
  display: flex;
  align-items: center;
  justify-content: center;

  height: 60px;

  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.accent};
`

const ContentList: React.ComponentType<any> = styled.div`
  padding: 10px;
`

type EntryProps = {|
  +active?: boolean,
  +children: React.Node,
  +onClick: () => void,
|}

export function Entry({ active, children, onClick }: EntryProps) {
  return (
    <EntryWrapper active={active} onClick={onClick}>
      {children}
    </EntryWrapper>
  )
}

const EntryWrapper: React.ComponentType<any> = styled.a`
  ${paragraph4};
  display: flex;
  align-items: center;

  cursor: pointer;
  height: 30px;
  padding: 0 15px;

  color: ${({ theme }) => theme.accent};

  ${({ active, theme }) =>
    active
      ? `
    color: ${theme.primary};
    background-color: ${theme.accent};
  `
      : ''}

  &:hover {
    ${({ active, theme }) =>
      active ? '' : `background-color: ${transparentize(0.95, theme.accent)};`}
  }
`
