// @flow

import type { ComponentType } from 'react'
import React, { useState } from 'react'

import styled from '@emotion/styled'

import { Icon, text } from '@toggl/ui'
import { colors, media } from '@toggl/style'

type Props = {
  allowMultipleOpen?: boolean,
  active: number,
  title: string,
  sections: Array<{
    title: string,
    description: string,
  }>,
}
export const Accordion = ({
  title,
  sections,
  active,
  allowMultipleOpen = false,
}: Props) => {
  const initialState = active ? { [active]: true } : {}
  const [openSections, setOpen] = useState(initialState)

  const onClick = (id) => {
    const nextState = !openSections[id]
    if (allowMultipleOpen) {
      setOpen({ ...openSections, [id]: nextState })
    } else {
      setOpen({ [id]: nextState })
    }
  }

  return (
    <Root>
      <Title>{title}</Title>
      {sections.map((section, idx) => {
        const isOpen = !!openSections[idx]

        return (
          <Section key={`section_${idx}`} isOpen={isOpen}>
            <SectionHeader isOpen={isOpen} onClick={() => onClick(idx)}>
              <SectionTitle isOpen={isOpen}>{section.title}</SectionTitle>
              <div>
                {!isOpen && <Icon.ArrowDown />}
                {isOpen && <Icon.ArrowUp />}
              </div>
            </SectionHeader>
            {isOpen && <Description>{section.description}</Description>}
          </Section>
        )
      })}
    </Root>
  )
}

const Description: ComponentType<any> = styled.div`
  ${text.paragraph3};
  color: ${colors.fadedPurple};
  margin-top: 18px;

  ${media.mq[1]} {
    margin-top: 18px;
  }
`

const Section: ComponentType<any> = styled.div`
  padding: 30px;

  border: 1px solid ${colors.darkPurple};
  border-left: none;
  border-right: none;
  border-bottom: none;

  &:last-of-type {
    border-bottom: 1px solid ${colors.darkPurple};
  }
  background-color: ${({ isOpen, theme }) => (isOpen ? theme.primary : 'none')};

  ${media.mq[1]} {
    padding: ${({ isOpen }) =>
      isOpen ? '32px 33px 46px 53px' : '20px 33px 25px 53px'};

    background-color: ${({ isOpen, theme }) =>
      isOpen ? theme.primary : 'none'};
  }
`

const SectionTitle: ComponentType<any> = styled.h4`
  display: inline-block;
  width: 208px;
  font-size: 1.25rem;
  color: ${({ isOpen }) => (isOpen ? colors.fadedPurple : colors.beige)};

  ${media.mq[1]} {
    width: 100%;
    ${text.heading4};
    color: ${({ isOpen }) => (isOpen ? colors.fadedPurple : colors.beige)};
  }
`

const SectionHeader: ComponentType<any> = styled.div`
  ${text.heading4};
  color: ${({ isOpen }) => (isOpen ? colors.darkPurple : colors.beige)};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`

const Title: ComponentType<any> = styled.div`
  ${text.heading1};
  color: ${({ isOpen }) => (isOpen ? colors.darkPurple : colors.beige)};
  text-align: center;
  margin-bottom: 27px;

  ${media.mq[1]} {
    ${text.heading2};
    color: ${({ isOpen }) => (isOpen ? colors.fadedPurple : colors.beige)};
    margin-bottom: 52px;
  }
`

const Root: ComponentType<any> = styled.div`
  display: flex;
  flex-direction: column;

  color: ${colors.yellow};
`
