// @flow
import * as React from 'react'

export const Lock = () => {
  return (
    <svg viewBox="0 0 20 30" version="1.1">
      <title>Group 7</title>
      <g
        id="Desktop-Track"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Toggl_Login" transform="translate(-628.000000, -794.000000)">
          <g id="LogIn" transform="translate(384.000000, 384.000000)">
            <g id="Group-7" transform="translate(244.000000, 410.000000)">
              <g
                id="Group-4"
                transform="translate(6.000000, 17.000000)"
                fill="#FCE5DB"
              >
                <polygon
                  id="Rectangle"
                  transform="translate(4.857079, 3.111111) rotate(45.000000) translate(-4.857079, -3.111111) "
                  points="4.19041206 -0.444444444 5.52374539 -0.444444444 5.52374539 6.66666667 4.19041206 6.66666667"
                ></polygon>
                <polygon
                  id="Rectangle-Copy"
                  transform="translate(2.000000, 4.347771) scale(-1, 1) rotate(45.000000) translate(-2.000000, -4.347771) "
                  points="1.33333333 2.56999359 2.66666667 2.56999359 2.66666667 6.12554915 1.33333333 6.12554915"
                ></polygon>
              </g>
              <rect
                id="Rectangle"
                stroke="#FCE5DB"
                strokeWidth="1.5"
                x="0.75"
                y="10.75"
                width="18.5"
                height="18.5"
              ></rect>
              <path
                d="M10,1.25 C11.3116763,1.25 12.4991763,1.78166186 13.3587572,2.64124279 C14.2183381,3.50082372 14.75,4.68832372 14.75,6 L14.75,6 L14.75,10.749939 L5.25,10.7491659 L5.25,6 C5.25,4.68832372 5.78166186,3.50082372 6.64124279,2.64124279 C7.50082372,1.78166186 8.68832372,1.25 10,1.25 Z"
                id="Rectangle"
                stroke="#FCE5DB"
                strokeWidth="1.5"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
