// @flow

import type { Response } from './types'

/*::
const assertErrorResponse = (r: Response) => {
  if (r.type === 'error') return r
  throw Error()
}
*/

type ErrorResponse = $Call<typeof assertErrorResponse, Response>
type Meta = $Call<<T>({ +type: 'error', +meta: T, ... } | any) => T, Response>

// Supports all package errors and provides a convenient meta property for
// logging errors with Bugsnag for debugg.
export class AuthError extends Error {
  name = 'AuthError'
  meta: Meta

  constructor(response: ErrorResponse) {
    super(response.message)

    this.meta = response.meta
  }
}

// Unexpected and programmer errors.
export class UnexpectedError extends Error {
  name = 'UnexpectedError'
  meta: Meta

  constructor(message: string, meta?: Meta = {}) {
    super(message)

    this.meta = meta ?? { error: this.toString() }
  }
}
