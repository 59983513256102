// @flow

import type { ComponentType, Node } from 'react'
import * as React from 'react'
import styled from '@emotion/styled'

import { colors, media } from '@toggl/style'
import { text } from '@toggl/ui'

type Props = {
  size: 1 | 2,
  title: Node,
  subTitle?: Node,
  Cta?: ComponentType<any>,
  image?: string,
  video?: React.Node,
  color?: string,
  align?: string,
}

export default function CallToAction({
  size,
  title,
  subTitle,
  Cta,
  image,
  color,
  video,
  align,
}: Props) {
  return (
    <Root align={align} size={size}>
      {image && <ImageStyled src={image} />}
      {video}
      {size === 1 && (
        <>
          <H1 align={align} color={color}>
            {title}
          </H1>
          {subTitle && (
            <P1 align={align} color={color}>
              {subTitle}
            </P1>
          )}
        </>
      )}
      {size === 2 && (
        <>
          <H2 align={align} color={color}>
            {title}
          </H2>
          {subTitle && (
            <P2 align={align} color={color}>
              {subTitle}
            </P2>
          )}
        </>
      )}

      {Cta && <Cta />}
    </Root>
  )
}

const H1 = styled.h1`
  ${text.heading1};
  color: ${(p) => p.color || p.theme.primary};
  margin-bottom: 25px;

  ${media.mq[1]} {
    margin-bottom: 25px;
    display: inline-block;
  }
  text-align: ${(p) => p.align || 'center'};
`
const H2 = styled.h2`
  ${text.heading2};
  color: ${(p) => p.color || p.theme.primary};
  margin-bottom: 18px;

  ${media.mq[1]} {
    margin-bottom: 25px;
  }
  text-align: ${(p) => p.align || 'center'};
`
const P1 = styled.p`
  ${text.paragraph3};
  ${media.mq[1]} {
    ${text.paragraph1};
    color: ${(p) => p.color || colors.beige};
  }
  color: ${(p) => p.color || colors.beige};
  text-align: ${(p) => p.align || 'center'};
`
const P2 = styled.p`
  ${text.paragraph3};
  ${media.mq[1]} {
    ${text.paragraph2};
    color: ${(p) => p.color || colors.beige};
  }

  color: ${(p) => p.color || colors.beige};

  text-align: ${(p) => p.align || 'center'};
`

const ImageStyled = styled.img`
  max-height: 110px;
  margin-bottom: 25px;

  ${media.mq[1]} {
    max-height: 161px;
    margin-bottom: 50px;
  }
`

export const Root: ComponentType<any> = styled.div`
  display: inline-block;
  text-align: ${(p) => (p.size === 2 ? 'center' : 'left')};
  text-align: ${(p) => p.align || 'center'};
  video {
    max-width: 200px;
  }

  ${media.mq[1]} {
    text-align: center;
    video {
      max-width: 400px;
    }
  }

  button {
    margin: 24px ${(p) => (p.align === 'left' ? '0' : 'auto')};

    ${media.mq[1]} {
      margin: 27px ${(p) => (p.align === 'left' ? '0' : 'auto')};
    }
  }
`
