// @flow
import styled from '@emotion/styled'
import * as React from 'react'

import { colors, media } from '@toggl/style'

import * as text from '../text'
import WorkflowCard from '../card/workflow'

type Props = {|
  +children: $ReadOnlyArray<React.Element<typeof WorkflowCard>>,
  +link: React.Node,
  +subtitle: React.Node | string,
  +title: React.Node | string,
|}

export default function Workflow({ children, subtitle, title, link }: Props) {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <Cards>{children}</Cards>
      {link}
    </Wrapper>
  )
}

const Wrapper: React.ComponentType<any> = styled.div`
  display: flex;
  flex-direction: column;

  width: 250px;
  ${media.mq[1]} {
    width: 950px;
  }

  text-align: center;

  button {
    margin: 0 auto;
  }
`

const Title: React.ComponentType<any> = styled.div`
  ${text.heading4};
  color: ${colors.darkPurple};
  ${media.mq[1]} {
    ${text.heading2};
    color: ${colors.darkPurple};
    margin-bottom: 25px;
  }

  color: ${colors.darkPurple};
`

const Subtitle: React.ComponentType<any> = styled.div`
  ${text.paragraph2};
  color: ${colors.darkPurple};
  margin-bottom: 20px;

  ${media.mq[1]} {
    ${text.paragraph1};
    color: ${colors.darkPurple};
    margin-bottom: 55px;
  }

  color: ${colors.fadedPurple};
`

const Cards: React.ComponentType<any> = styled.div`
  display: flex;
  flex-direction: column;

  > div:not(:last-child) {
    margin-bottom: 60px;
  }

  ${media.mq[1]} {
    flex-direction: row;

    > div:not(:last-child) {
      margin-bottom: 0;
      margin-right: 110px;
    }
  }

  margin-bottom: 60px;
`
