import styled from '@emotion/styled'
import { defaultText } from './text'
import { colors } from '@toggl/style'

export const Main = styled.ul`
  ${defaultText};
  display: flex;

  li {
    a {
      text-decoration: none;
      color: ${colors.light};
    }
  }
`
