// @flow
import type { Plugin } from '@bugsnag/core'

// Extends the client with support for connectivity breadcrumbs and metadata.
const connectivity: Plugin = {
  name: '@toggl-connectivity',
  load(client) {
    return {
      setOffline(reason: string) {
        const offline = true

        client.addMetadata('connectivity', { offline, interruptions: true })
        client.leaveBreadcrumb(
          'Connectivity changed',
          { offline, reason },
          'state'
        )
      },
      setOnline() {
        const offline = false

        client.addMetadata('connectivity', { offline })
        client.leaveBreadcrumb('Connectivity changed', { offline }, 'state')
      },
    }
  },
}

export default connectivity
