// @flow

import type { ComponentType } from 'react'
import * as React from 'react'
import styled from '@emotion/styled'

import { colors, fonts, media } from '@toggl/style'

import * as text from '../text'
import { Button } from '../../index'

type Props = {
  Logo: ComponentType<any>,
  Cta: ComponentType<any>,
  image?: string,
  video?: React.Node,
  title: string,
}

export default function Product({ Logo, Cta, image, title, video }: Props) {
  return (
    <Root>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      {image ? <Image src={image} /> : <VideoWrapper>{video}</VideoWrapper>}
      <Title>{title}</Title>
      <CtaWrapper>
        <Cta />
      </CtaWrapper>
      <ButtonReadMore>or discover features</ButtonReadMore>
    </Root>
  )
}

const VideoWrapper = styled.div`
  width: 160px;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${(p) => p.theme.primary};

  video {
    max-width: 100%;
  }
`

const Image = styled.img`
  height: 123px;
  ${media.mq[1]} {
    height: 150px;
  }
`

const ButtonReadMore = styled(Button.Secondary)`
  margin-top: 17px;
  color: ${(p) => p.theme.primary};

  span {
    border-left-color: ${(p) => p.theme.primary};
  }

  ${media.mq[1]} {
    margin-top: 23px;
    color: ${(p) => p.theme.primary};
  }
`

const Title = styled.h5`
  ${text.heading4};
  font-family: ${fonts.gtHaptik};
  color: ${colors.darkPurple};
  font-size: 1.3125rem;

  margin-top: 14px;
  margin-bottom: 32px;

  ${media.mq[1]} {
    margin-top: 20px;
    margin-bottom: 22px;
    font-family: ${fonts.gtHaptik};

    min-height: 60px;
  }
`

const LogoWrapper = styled.div`
  height: 28px;
  margin-bottom: 24px;

  svg {
    height: 100%;
  }

  ${media.mq[1]} {
    height: 32px;
    margin-bottom: 32px;
  }
`

const CtaWrapper = styled.div``

const Root: ComponentType<any> = styled.div`
  ${text.defaultText};
  background-color: ${colors.beige};

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 410px;
  padding: 24px 27px;
  margin-bottom: 20px;

  ${media.mq[1]} {
    max-width: 365px;
    min-height: 500px;
    padding: 40px 48px;
    margin-bottom: 0;
  }
`

export const ProductSection: ComponentType<any> = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  ${media.mq[1]} {
    gap: 40px;
    flex-direction: row;
  }
`
