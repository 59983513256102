// @flow

import type { ComponentType, Node } from 'react'
import React, { useLayoutEffect, useRef, useState } from 'react'

import styled from '@emotion/styled'
import { colors, media } from '@toggl/style'
import { hooks, Icon } from '../../index'
import * as text from '../text'
import Links from './links'

export { Product, ProductsGroup } from './product'
export { default as MenuContent } from './menuContent'
export { BackSocial } from './backSocial'

type Props = {
  contents: any,
  Logo: ComponentType<any>,
  Cta: ComponentType<any>,
  children: Node,
  login: Node,
  logoWidth?: [string, string],
}

export const Header = ({
  contents,
  Logo,
  children,
  Cta,
  login,
  logoWidth,
}: Props) => {
  const wrapperRef = useRef(null)
  const linksWrapperRef = useRef(null)

  const [contentKey, _setContentKey] = useState(null)

  const setContentKey = (val) => {
    console.log(val)
    if (contentKey === val || contents[val] == null) {
      return _setContentKey(null)
    }
    _setContentKey(val)
  }
  const isDesktop = hooks.useMediaQuery(media.mqbp[1])
  const content = contentKey && contents[contentKey]
  const [open, setOpen] = useState(false)

  const linksRef = useRef(null)
  const [contentPadding, setContentPadding] = useState(null)
  const [mobileScreenHeight, setMobileScreenHeight] = useState(null)
  useLayoutEffect(() => {
    setContentPadding(linksRef.current?.offsetLeft)
    if (!isDesktop) {
      setMobileScreenHeight(window.innerHeight)
    }
  }, [isDesktop])

  return (
    <div ref={wrapperRef}>
      <HeaderLine open={open || (isDesktop && !!contentKey)}>
        <LogoWrapper logoWidth={logoWidth}>
          <Logo dark={open || (isDesktop && !!contentKey) || !isDesktop} />
        </LogoWrapper>

        {isDesktop && (
          <>
            <RefDiv ref={linksRef}>
              <StyledLinks
                icon={
                  contentKey && contentKey === 'products' ? (
                    <ArrowUp />
                  ) : (
                    <ArrowDown dark={contentKey} />
                  )
                }
                open={!!contentKey}
                setContentKey={setContentKey}
                contentKey={contentKey}
              >
                {children}
              </StyledLinks>
            </RefDiv>
            <CtaWrapper>
              <LoginWrapper
                onClick={() => setContentKey('login')}
                open={!!contentKey}
              >
                {login}
              </LoginWrapper>
              <CtaButtonWrapper onClick={() => setContentKey('cta')}>
                <Cta />
              </CtaButtonWrapper>
            </CtaWrapper>
          </>
        )}

        <LinksWrapper
          open={open || (isDesktop && !!contentKey)}
          subMenuOpen={!!contentKey}
          contentPadding={contentPadding}
          ref={linksWrapperRef}
          mobileScreenHeight={mobileScreenHeight}
        >
          {!isDesktop && !contentKey && (
            <>
              <Links icon={<StyledCaretIcon />} setContentKey={setContentKey}>
                {children}
              </Links>

              <CtaWrapper>
                <CtaButtonWrapper onClick={() => setContentKey('cta')}>
                  <Cta />
                </CtaButtonWrapper>
                <div onClick={() => setContentKey('login')}>{login}</div>
              </CtaWrapper>
            </>
          )}

          {contentKey && (
            <StyledMenu
              open={!!contentKey}
              compact={!isDesktop && !!contentKey}
            >
              {!isDesktop && (
                <StyledGoBack
                  onClick={() => {
                    setContentKey(null)
                  }}
                >
                  <Icon.ArrowBigLeft />
                  <span>Back</span>
                </StyledGoBack>
              )}
              {content && <ContentWrapper>{content}</ContentWrapper>}
            </StyledMenu>
          )}
        </LinksWrapper>
        <Burger
          open={open}
          onClick={() => {
            setOpen(!open)
          }}
        >
          <div />
          <div />
          <div />
        </Burger>
      </HeaderLine>
    </div>
  )
}
const RefDiv = styled.div`
  margin-right: auto;
  margin-left: 7%;
`
const LoginWrapper = styled.div`
  margin-right: 50px;
  button {
    color: ${(p) => (p.open ? colors.darkPurple : colors.almostWhite)};
  }
`

const ArrowDown = styled.div`
  margin-left: 10px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid
    ${(p) => (p.dark ? colors.darkPurple : colors.almostWhite)};
`

const ArrowUp = styled.div`
  margin-left: 10px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;

  border-bottom: 4px solid ${colors.darkPurple};
`

const StyledCaretIcon = styled(Icon.CaretRight)`
  margin-left: auto;
  align-self: center;
`

const StyledLinks = styled(Links)`
  height: 24px;
  li {
    a {
      text-decoration: none;
      color: ${(p) => (p.open ? colors.darkPurple : colors.almostWhite)};
    }
    ${media.mq[1]} {
      ${text.heading5};
      color: ${(p) => (p.open ? colors.darkPurple : colors.almostWhite)};
    }
  }
`
const ContentWrapper = styled.div`
  padding-top: 27px;

  ${media.mq[1]} {
    padding-top: 8px;
  }
`

const CtaButtonWrapper = styled.div`
  margin-bottom: 20px;

  ${media.mq[1]} {
    margin: 0;
  }
`

const LogoWrapper = styled.div`
  width: ${(p) => (p.logoWidth && p.logoWidth[0] ? p.logoWidth[0] : '64px')};
  display: flex;

  ${media.mq[1]} {
    width: ${(p) => (p.logoWidth && p.logoWidth[1] ? p.logoWidth[1] : '85px')};
  }
  svg {
    width: 100%;
  }
`

const headerHeight = 64
const headerHeightDesktop = 88

const HeaderLine: ComponentType<any> = styled.div`
  background-color: ${(p) => p.theme.background};
  border-bottom: solid 1px ${(p) => p.theme.borderColor};
  padding: 0 20px;
  height: ${headerHeight}px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.mq[1]} {
    height: ${headerHeightDesktop}px;
    border-bottom: none;
    background-color: ${({ open, theme }) =>
      open ? theme.background : 'transparent'};
  }
`

const Burger: ComponentType<any> = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 24px;
  height: 18px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  ${media.mq[1]} {
    display: none;
  }

  div {
    width: 24px;
    height: 2px;
    background: ${colors.darkPurple};
    border-radius: 10px;
    position: relative;
    transform-origin: 3px;

    :first-of-type {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-of-type(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }

    :nth-of-type(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`

const StyledMenu: ComponentType<any> = styled.div`
  flex-direction: column;
  display: ${(p) => (p.open ? 'flex' : 'none')};
  width: calc(100vw - 40px);

  ${media.mq[1]} {
    display: ${({ open }) => (open ? 'block' : 'none')};
    max-width: 960px;
  }
`

const CtaWrapper: ComponentType<any> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;

  ${media.mq[1]} {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0;
  }
`

const StyledGoBack: ComponentType<any> = styled.div`
  display: flex;
  align-items: center;
  & > span {
    margin-left: 20px;
    ${text.paragraph3};
  }
`

const LinksWrapper: ComponentType<any> = styled.div`
  position: absolute;
  top: ${headerHeight}px;
  z-index: 20;
  margin: 0 -20px;
  width: 100%;
  background: ${(p) => p.theme.background};
  padding: 30px 20px;
  ${text.heading4};
  height: ${(p) =>
    p.mobileScreenHeight
      ? `${p.mobileScreenHeight - headerHeight}px`
      : `calc(100vh - ${headerHeight}px) `};
  display: ${(p) => (p.open ? 'flex' : 'none')};
  flex-direction: column;

  > ul > li {
    margin-bottom: 25px;

    a {
      text-decoration: none;
      color: ${colors.fadedPurple};
    }
  }

  ${media.mq[1]} {
    top: ${headerHeightDesktop}px;
    height: auto;
    padding: 0 20px;
    padding-left: ${(p) => (p.contentPadding ? `${p.contentPadding}px` : '8%')};
    overflow: hidden;
  }
`
