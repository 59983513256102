// @flow
import type { Event } from '@bugsnag/core'

// Tools for verbose logging with Bugsnag.
export const logger = ['debug', 'info', 'warn', 'error'].reduce(
  (logger, method) => {
    if (method !== 'debug') {
      logger[method] = console[method].bind(console, '[bugsnag]')
    } else {
      logger[method] = (...args: mixed[]) => {
        if (typeof args[0] === 'string' && args[0].includes('Loaded!')) {
          return
        }

        return console[method]('[bugsnag]', ...args)
      }
    }

    return logger
  },
  {}
)

export const onError = ({ originalError }: Event) => {
  if (originalError && originalError instanceof Error) {
    console.error(originalError)
  }
}
