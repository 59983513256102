// @flow

import * as React from 'react'

export const Calendar = () => (
  <svg viewBox="0 0 28 28" version="1.1">
    <g
      id="Desktop-Track"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Toggl_Business_page"
        transform="translate(-356.000000, -1541.000000)"
        fill="#2C1338"
        fillRule="nonzero"
      >
        <g id="USP" transform="translate(354.000000, 1410.000000)">
          <g id="USPs" transform="translate(0.000000, 129.000000)">
            <path
              d="M26.1106118,4.33390944 L23.7767024,4.33390944 L23.7767024,2 L22.2212236,2 L22.2212236,4.33390944 L9.77739371,4.33390944 L9.77739371,2 L8.22191497,2 L8.22191497,4.33390944 L5.88938818,4.33390944 C3.74490149,4.33390944 2,6.07881092 2,8.22329761 L2,26.1119945 C2,28.2564812 3.74490149,30.0013826 5.88938818,30.0013826 L26.1106118,30.0013826 C28.2550985,30.0013826 30,28.2564812 30,26.1119945 L30,12.1113031 L30,10.5558244 L30,8.22191497 C29.9986174,6.07742828 28.2537159,4.33390944 26.1106118,4.33390944 Z M28.4431386,26.1106118 C28.4431386,27.3964742 27.3964742,28.4431386 26.1106118,28.4431386 L5.88938818,28.4431386 C4.6021431,28.4431386 3.55547874,27.3964742 3.55547874,26.1106118 L3.55547874,12.1113031 L28.4431386,12.1113031 L28.4431386,26.1106118 Z M28.4431386,10.5558244 L3.55547874,10.5558244 L3.55547874,8.22191497 C3.55547874,6.93466989 4.6021431,5.88800553 5.88938818,5.88800553 L8.22329761,5.88800553 L8.22329761,7.44348427 L9.77739371,7.44348427 L9.77739371,5.88938818 L22.2212236,5.88938818 L22.2212236,7.44486692 L23.7767024,7.44486692 L23.7767024,5.88938818 L26.1106118,5.88938818 C27.3964742,5.88938818 28.4431386,6.93466989 28.4431386,8.22191497 L28.4431386,10.5558244 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
