// @flow

import React from 'react'
import type { ComponentType, Node } from 'react'
import styled from '@emotion/styled'

import { colors, media } from '@toggl/style'
import { hooks, text } from '@toggl/ui'

type Props = {
  skewed?: boolean,
  bgImage?: string,
  mobileBgImage?: string,
  bgVideo?: string,
  children: Node,
  solidColor?: string,
}
export const Wrapper = ({
  bgImage,
  mobileBgImage,
  bgVideo,
  skewed,
  children,
  solidColor,
  ...props
}: Props) => {
  const isDesktop = hooks.useMediaQuery(media.mqbp[1])

  return (
    <Root solidColor={solidColor} {...props}>
      {bgImage && (
        <ImgBackground
          bgImage={mobileBgImage && !isDesktop ? mobileBgImage : bgImage}
          hideOnDesktop={!!bgVideo}
          skewed={skewed}
        />
      )}
      {isDesktop && bgVideo && (
        <VideoWrapper skewed={skewed}>
          <div />
          <VideoStyled autoPlay muted loop skewed={skewed} poster={bgImage}>
            <source src={bgVideo} />
          </VideoStyled>
        </VideoWrapper>
      )}
      {children}
    </Root>
  )
}

const VideoWrapper: ComponentType<any> = styled.div`
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 0;

  ${({ skewed }) =>
    skewed && 'animation: spinner 1s; height: calc(100% - 150px);'}
  animation-fill-mode: forwards;
  animation-delay: 0.4s;

  @keyframes spinner {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(10deg);
    }
  }

  div {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    width: 100%;
    height: 100%;
    max-width: 1920px;
    object-fit: cover;
    position: relative;
    z-index: 2;
  }

  ${media.mq[3]} {
    ${({ skewed }) => skewed && 'height: calc(100% - 250px);'}
  }
`

// 1225px based on video size
const VideoStyled: ComponentType<any> = styled.video`
  position: absolute;
  z-index: -1;
  top: 0;
  width: 100%;
  ${({ skewed }) => skewed && 'width: unset; height: 100%;'}

  @media (min-width: 1225px) {
    ${({ skewed }) => skewed && 'width: 100%; height: unset;'}
  }
`

type ImgBGProps = {
  skewed?: boolean,
  bgImage: string,
  hideOnDesktop: boolean,
}
export const ImgBackground: ComponentType<ImgBGProps> = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${({ color }) => color};
  ${({ skewed }) =>
    skewed && 'transform: rotate(10deg); height: calc(100% - 35px);'}
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${(
    p
  ) => p.bgImage});

   ${media.mq[1]} {
    ${({ hideOnDesktop }) => hideOnDesktop && 'display: none;'}
  }
`

export const ContentWrapper: ComponentType<any> = styled.div`
  position: relative;
  padding: 30px;

  ${media.mq[1]} {
    padding-top: 20px;
    padding-bottom: 90px;
    max-width: 1200px;
    margin: 0 auto;
  }

  ${media.mq[2]} {
    padding-left: 0;
    padding-right: 0;
  }
`

export const Title: ComponentType<any> = styled.div`
  ${text.heading3};
  color: ${colors.almostWhite};
  margin-bottom: 25px;
  text-align: ${(p) => (p.centered ? 'center' : 'left')};

  ${media.mq[1]} {
    ${text.heading1};
  }

  em {
    color: ${(p) => p.theme.primary};
  }
`

export const Text: ComponentType<any> = styled.div`
  ${text.heading5};
  color: ${colors.beige};
  margin-top: 10px;
  margin-bottom: 25px;
  width: 190px;

  ${media.mq[1]} {
    width: 100%;
  }
`

export const CtaSection: ComponentType<any> = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;

  button,
  button span {
    color: ${colors.almostWhite};
  }

  ${media.mq[1]} {
    flex-direction: row;
  }
`

export const Root: ComponentType<any> = styled.div`
  position: relative;
  min-height: 200px;

  height: 50%;
  ${(p) => (p.solidColor ? `background-color: ${p.solidColor};` : '')}
  z-index: 10000;
`

export const LoginSmallTitle: ComponentType<any> = styled.div`
  ${text.paragraph2};
  color: ${colors.almostWhite};
  text-align: center;
  margin-bottom: 15px;

  ${media.mq[1]} {
    margin-bottom: 30px;
  }
`

export const LoginWrapper: ComponentType<any> = styled(ContentWrapper)`
  ${media.mq[1]} {
    padding: 80px 0;
  }
`
