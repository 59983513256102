// @flow

export const heading1 = '5rem' // 80
export const heading2 = '3.5rem' // 56
export const heading3 = '2rem' // 32
export const heading4 = '1.5rem' // 24
export const heading5 = '1rem' // 16

export const paragraph1 = '1.5rem' // 24
export const paragraph2 = '1.125rem' // 18
export const paragraph3 = '1rem' // 16
export const paragraph4 = '0.875rem' // 14

export const defaultText = '1rem' // 16
