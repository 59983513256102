// @flow

import * as React from 'react'

import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'

import { Button, Icon, text } from '@toggl/ui'
import { colors, media } from '@toggl/style'

type FeatureType = {|
  +id: string,
  +title: string,
  +description: {|
    +title: string,
    +content: string,
  |},
  +free: boolean,
  +starter: boolean,
  +premium: boolean,
|}

type Props = {|
  +className?: string,
  +features: {|
    +[group: string]: {|
      +header: string,
      +entries: $ReadOnlyArray<FeatureType>,
    |},
  |},
  +initialVisibleGroups?: number,
  +showMoreText?: string,
  +title: string,
  +subtitle: string,
|}

export default function PlanComparisonTable({
  className,
  features,
  initialVisibleGroups,
  showMoreText,
  title,
  subtitle,
}: Props) {
  const [expandedFeature, setExpandedFeature] = React.useState({})
  const onToggleFeature = (id, newState) => {
    setExpandedFeature(newState ? id : undefined)
  }

  const [numberOfVisibleGroups, setNumberOfVisibleGroups] = React.useState(
    initialVisibleGroups || Object.keys(features).length
  )

  const showAllFeatures = () => {
    setNumberOfVisibleGroups(Object.keys(features).length)
  }

  return (
    <Wrapper className={className}>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <Table>
        {(Object.values(features): any[]).map(
          ({ header, entries }, indx) =>
            indx < numberOfVisibleGroups && (
              <FeatureGroup
                key={`feature_group_${indx}`}
                name={header}
                features={entries}
                isFirst={indx === 0}
                onToggleFeature={onToggleFeature}
                expandedFeature={expandedFeature}
              />
            )
        )}
      </Table>
      {numberOfVisibleGroups !== Object.keys(features).length && showMoreText && (
        <ButtonWrapper>
          <Button.Secondary caretDirection="down" onClick={showAllFeatures}>
            {showMoreText}
          </Button.Secondary>
        </ButtonWrapper>
      )}
    </Wrapper>
  )
}

const FeatureGroup = ({
  name,
  features,
  isFirst,
  expandedFeature,
  onToggleFeature,
}) => (
  <React.Fragment>
    <GroupName isFirst={isFirst}>{name}</GroupName>
    {isFirst && (
      <React.Fragment>
        <PlanName>Free</PlanName>
        <PlanName>Starter</PlanName>
        <PlanName>Premium</PlanName>
        <div />
      </React.Fragment>
    )}
    {features.map((feature) => (
      <Feature
        onToggleFeature={onToggleFeature}
        key={feature.id}
        open={feature.id === expandedFeature}
        {...feature}
      />
    ))}
  </React.Fragment>
)

const ButtonWrapper = styled.div`
  margin-top: 20px;

  ${media.mq[1]} {
    margin-top: 60px;
  }
`

const headingStyles = css`
  ${text.paragraph4};
  font-size: 0.75rem;

  ${media.mq[1]} {
    font-size: 0.875rem;

    span {
      padding-left: 30px;
    }
  }
`

const Table: React.ComponentType<any> = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr repeat(3, 30px) 10px;
  grid-row-gap: 10px;

  ${media.mq[1]} {
    grid-template-columns: 1fr repeat(3, 100px) auto;
    grid-row-gap: 15px;
  }
`

const PlanName = styled.div`
  ${headingStyles};
  transform: rotate(180deg);
  writing-mode: vertical-lr;

  ${media.mq[1]} {
    transform: none;
    writing-mode: unset;
  }

  color: ${({ theme }) => theme.primary};
`

const GroupName = styled.div`
  ${headingStyles};
  display: flex;
  align-items: flex-end;
  color: ${({ theme }) => theme.primary};

  ${media.mq[1]} {
    padding-left: 30px;
  }

  ${({ isFirst }) => (isFirst ? '' : 'grid-column: span 5;')}
`

const Wrapper: React.ComponentType<any> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title: React.ComponentType<any> = styled.h1`
  ${text.heading4};
  margin-bottom: 20px;

  ${media.mq[1]} {
    ${text.heading3};
  }

  color: ${({ theme }) => theme.primary} !important;
`

const Subtitle: React.ComponentType<any> = styled.h2`
  ${text.heading5};
  margin-bottom: 20px;

  ${media.mq[1]} {
    ${text.heading4};
    padding-bottom: 30px;
  }

  color: ${({ theme }) => theme.accent};
`

const Feature = ({
  id,
  title,
  description,
  free,
  starter,
  premium,
  open,
  onToggleFeature,
}) => {
  const theme = useTheme()
  const checkIconColor = open ? colors.fadedPurple : theme.accent

  const toggleFeature = () => {
    onToggleFeature(id, !open)
  }

  return (
    <React.Fragment>
      <FeatureTitle onClick={toggleFeature} open={open}>
        <span>
          {!open && <EnhancedArrowDown />}
          {open && <EnhancedArrowUp />}
        </span>
        <span>{title}</span>
      </FeatureTitle>
      {[free, starter, premium].map((plan, i) => (
        <Check
          key={`${id}_plan_${i}`}
          onClick={toggleFeature}
          open={open}
          desktopLeftPadding={(i + 1) * 5}
        >
          {plan && <Icon.Check color={checkIconColor} />}
        </Check>
      ))}
      <Spacer open={open} onClick={toggleFeature} />
      {open && (
        <FeatureDescription>
          <FeatureDescriptionTitle>{description.title}</FeatureDescriptionTitle>
          <FeatureDescriptionContent>
            {description.content}
          </FeatureDescriptionContent>
        </FeatureDescription>
      )}
    </React.Fragment>
  )
}

const EnhancedArrowDown = styled(Icon.ArrowDown)`
  position: relative;
  top: -3px;
`

const EnhancedArrowUp = styled(Icon.ArrowUp)`
  position: relative;
  top: 4px;
`

const Check: React.ComponentType<any> = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${media.mq[1]} {
    justify-content: flex-start;

    padding-left: ${({ desktopLeftPadding }) => desktopLeftPadding}px;
  }

  background-color: ${({ open }) => (open ? colors.yellow : colors.darkPurple)};
  cursor: pointer;
`

const FeatureTitle = styled.div`
  display: flex;

  span:first-child {
    margin-right: 10px;

    ${media.mq[1]} {
      margin-right: 15px;
    }
  }

  padding: 20px 0;

  ${text.paragraph3};
  padding-left: 20px;
  ${media.mq[1]} {
    padding-left: 30px;
  }

  color: ${({ open, theme }) => (open ? colors.fadedPurple : theme.accent)};
  background-color: ${({ open }) => (open ? colors.yellow : colors.darkPurple)};
  cursor: pointer;
`

const Spacer = styled.div`
  background-color: ${({ open }) => (open ? colors.yellow : colors.darkPurple)};
  cursor: pointer;
`

const FeatureDescription = styled.div`
  ${text.paragraph4};
  background-color: ${colors.yellow};
  color: ${colors.fadedPurple};
  grid-column: span 5;
  margin-top: -20px;
  padding: 5px 20px 30px 20px;

  ${media.mq[1]} {
    padding-left: 30px;
  }
`

const FeatureDescriptionTitle = styled.div`
  ${text.paragraph3};
  color: ${colors.fadedPurple};
  margin-bottom: 10px;
  font-weight: 700;
`

const FeatureDescriptionContent = styled.div`
  ${text.paragraph4};
  color: ${colors.fadedPurple};
`
