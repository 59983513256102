// @flow
import * as React from 'react'
import Slider from 'react-slick'
import styled from '@emotion/styled'
import type { ComponentType } from 'react'
import { colors, media } from '@toggl/style'
import * as text from '../text'
import { Button, hooks } from '@toggl/ui'
import 'slick-carousel/slick/slick.css'

type Props = {
  children: React.Node[],
}

const StyledCircleButton = styled(Button.Circle)`
  margin-left: ${(p) => (p.floatRight ? 'auto' : 'inherit')};
  margin-right: ${(p) => (p.floatLeft ? 'auto' : 'inherit')};
`

export function DiagonAlley({ children }: Props) {
  const isDesktop = hooks.useMediaQuery(media.mqbp[1])
  const slider: {
    current: { slickNext: () => void, slickPrev: () => void } | null,
  } = React.useRef(null)
  const [active, setActive] = React.useState(0)

  const widthRef: { current?: any } = React.useRef()

  const settings = {
    centerMode: true,
    infinite: false,
    slidesToShow: isDesktop ? 3 : 1,
    centerPadding: '0',
    speed: 500,
    focusOnSelect: true,
    arrows: false,
    beforeChange: (oldIndex, index) => {
      setActive(index)
    },
  }

  const [offset, setOffset] = React.useState(0)
  React.useLayoutEffect(() => {
    if (!widthRef.current) {
      return
    }
    setOffset(widthRef.current.offsetWidth / 3 / 2 - 20)
  }, [])

  return (
    <Root>
      <Diagonal ref={widthRef}>
        <Slider {...settings} ref={slider}>
          {React.Children.map(children, (item, index) => {
            return (
              <div>
                <Pad
                  isActive={index === active}
                  isLeftOfActive={active + 1 === index}
                  isRightOfActive={active - 1 === index}
                >
                  <IconWrapper>{item.props.children[0]}</IconWrapper>
                  {item.props.children[1]}
                </Pad>
              </div>
            )
          })}
          {isDesktop && <FakeSlide></FakeSlide>}
          {isDesktop && <FakeSlide></FakeSlide>}
        </Slider>
      </Diagonal>
      <Controls offset={offset}>
        {active !== 0 && (
          <StyledCircleButton
            onClick={() => slider.current?.slickPrev()}
            arrowColor={colors.darkPurple}
            large
            floatLeft={active === React.Children.count(children) - 1}
            side="left"
          />
        )}

        {active !== React.Children.count(children) - 1 && (
          <StyledCircleButton
            onClick={() => slider.current?.slickNext()}
            floatRight={active === 0}
            arrowColor={colors.darkPurple}
            large
          />
        )}
      </Controls>
    </Root>
  )
}

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 70px 20px 70px;
  position: relative;
  transform: translateY(-40px);

  ${media.mq[1]} {
    position: absolute;
    pointer-events: none;
    top: 34%;
    width: 100%;
    transform: rotate(10deg);
    padding: 0 ${(p) => p.offset - 45}px;
    > button {
      transform: rotate(-10deg);
    }
  }
`

const FakeSlide = styled.div`
  background: transparent;
`

const Pad = styled.div`
  padding: 40px;
  transition: all 0.3s 0.1s;

  ${media.mq[1]} {
    ${(p) => (p.isActive ? 'isLeftOfActive' : '')}
    ${(p) => (p.isLeftOfActive ? 'transform: translateY(50px);' : '')}
    ${(p) => (p.isRightOfActive ? 'transform: translateY(-50px);' : '')}
  }
`

const IconWrapper = styled.div`
  padding: 59px 20px 17px 54px;
  margin: 0 auto;
  svg {
    transform: rotate(-10deg);
  }
`

const Root: ComponentType<any> = styled.div`
  overflow: visible;
  p {
    opacity: 0;
  }
  ${IconWrapper} {
    svg {
      opacity: 0;
    }
    background: ${colors.fadedPurple};
  }

  .slick-current {
    svg,
    p {
      opacity: 1;
    }

    ${IconWrapper} {
      background: ${colors.darkPurple};
    }
  }

  button {
    &.slick-arrow {
      position: absolute;
      z-index: 100;
    }
    &.slick-prev {
      bottom: 0;
      left: 35%;
    }
    &.slick-next {
      bottom: 34px;
      right: 5%;
    }
  }

  ${media.mq[1]} {
    button {
      &.slick-prev {
        top: 200px;
        left: 200px;
      }
      &.slick-next {
        top: 200px;
        right: 200px;
      }
    }
  }
`

const Diagonal = styled.div`
  transform: rotate(10deg);

  p,
  h4,
  button {
    transform: rotate(-10deg);
  }

  h4 {
    ${text.heading4};
    color: ${colors.darkPurple};
  }

  p {
    ${text.paragraph2};
    color: ${colors.darkPurple};
    margin-top: 20px;
  }
`
