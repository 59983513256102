// @flow

import * as React from 'react'

export const Group = () => (
  <svg viewBox="0 0 45 48" version="1.1">
    <g
      id="Desktop-Track"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Toggl_Business_page"
        transform="translate(-792.000000, -1771.000000)"
        fill="#2C1338"
        fillRule="nonzero"
      >
        <g id="USP" transform="translate(354.000000, 1410.000000)">
          <g id="USPs" transform="translate(0.000000, 129.000000)">
            <g id="Group" transform="translate(438.000000, 232.000000)">
              <path
                d="M6.77617021,33.0842553 C9.01787234,33.0842553 10.8459574,31.2612766 10.8459574,29.0144681 C10.8459574,26.7676596 9.02297872,24.9446809 6.77617021,24.9446809 C4.53446809,24.9446809 2.70638298,26.7676596 2.70638298,29.0144681 C2.70638298,31.2612766 4.53446809,33.0842553 6.77617021,33.0842553 Z M6.77617021,26.4817021 C8.17531915,26.4817021 9.31404255,27.6204255 9.31404255,29.0195745 C9.31404255,30.4187234 8.17531915,31.5574468 6.77617021,31.5574468 C5.37702128,31.5574468 4.23829787,30.4187234 4.23829787,29.0195745 C4.23829787,27.6204255 5.37702128,26.4817021 6.77617021,26.4817021 Z"
                id="Shape"
              ></path>
              <path
                d="M6.77617021,35.1012766 C3.28851064,35.1012766 0.454468085,37.9404255 0.454468085,41.4229787 L0.454468085,46.9582979 C0.454468085,47.3821277 0.796595745,47.7242553 1.22042553,47.7242553 C1.64425532,47.7242553 1.98638298,47.3821277 1.98638298,46.9582979 L1.98638298,41.4229787 C1.98638298,38.7829787 4.13617021,36.6331915 6.77617021,36.6331915 C9.41617021,36.6331915 11.5659574,38.7829787 11.5659574,41.4229787 L11.5659574,46.9582979 C11.5659574,47.3821277 11.9080851,47.7242553 12.3319149,47.7242553 C12.7557447,47.7242553 13.0978792,47.3821277 13.0978792,46.9582979 L13.0978792,41.4229787 C13.1029787,37.9404255 10.2638298,35.1012766 6.77617021,35.1012766 Z"
                id="Path"
              ></path>
              <path
                d="M34.0902128,29.0195745 C34.0902128,31.2612766 35.9131915,33.0893617 38.16,33.0893617 C40.4017021,33.0893617 42.2297872,31.266383 42.2297872,29.0195745 C42.2297872,26.772766 40.4068085,24.9497872 38.16,24.9497872 C35.9182979,24.9497872 34.0902128,26.772766 34.0902128,29.0195745 Z M38.16,26.4817021 C39.5591489,26.4817021 40.6978723,27.6204255 40.6978723,29.0195745 C40.6978723,30.4187234 39.5591489,31.5574468 38.16,31.5574468 C36.7608511,31.5574468 35.6221277,30.4187234 35.6221277,29.0195745 C35.6221277,27.6204255 36.7608511,26.4817021 38.16,26.4817021 Z"
                id="Shape"
              ></path>
              <path
                d="M38.16,35.1012766 C34.6723404,35.1012766 31.8382979,37.9404255 31.8382979,41.4229787 L31.8382979,46.9582979 C31.8382979,47.3821277 32.1804255,47.7242553 32.6042553,47.7242553 C33.0280851,47.7242553 33.3702128,47.3821277 33.3702128,46.9582979 L33.3702128,41.4229787 C33.3702128,38.7829787 35.52,36.6331915 38.16,36.6331915 C40.8,36.6331915 42.9497872,38.7829787 42.9497872,41.4229787 L42.9497872,46.9582979 C42.9497872,47.3821277 43.2919149,47.7242553 43.7157447,47.7242553 C44.1395745,47.7242553 44.4817021,47.3821277 44.4817021,46.9582979 L44.4817021,41.4229787 C44.4817021,37.9404255 41.6476596,35.1012766 38.16,35.1012766 Z"
                id="Path"
              ></path>
              <path
                d="M18.3982979,29.0195745 C18.3982979,31.2612766 20.2212766,33.0893617 22.4680851,33.0893617 C24.7148936,33.0893617 26.5378723,31.266383 26.5378723,29.0195745 C26.5378723,26.772766 24.7148936,24.9497872 22.4680851,24.9497872 C20.2212766,24.9497872 18.3982979,26.772766 18.3982979,29.0195745 Z M22.4680851,26.4817021 C23.867234,26.4817021 25.0059574,27.6204255 25.0059574,29.0195745 C25.0059574,30.4187234 23.867234,31.5574468 22.4680851,31.5574468 C21.0689362,31.5574468 19.9302128,30.4187234 19.9302128,29.0195745 C19.9302128,27.6204255 21.0689362,26.4817021 22.4680851,26.4817021 Z"
                id="Shape"
              ></path>
              <path
                d="M22.4680851,35.1012766 C18.9804255,35.1012766 16.146383,37.9404255 16.146383,41.4229787 L16.146383,46.9582979 C16.146383,47.3821277 16.4885106,47.7242553 16.9123404,47.7242553 C17.3361702,47.7242553 17.6782979,47.3821277 17.6782979,46.9582979 L17.6782979,41.4229787 C17.6782979,38.7829787 19.8280851,36.6331915 22.4680851,36.6331915 C25.1080851,36.6331915 27.2578723,38.7829787 27.2578723,41.4229787 L27.2578723,46.9582979 C27.2578723,47.3821277 27.6,47.7242553 28.0238298,47.7242553 C28.4476596,47.7242553 28.7897872,47.3821277 28.7897872,46.9582979 L28.7897872,41.4229787 C28.7897872,37.9404255 25.9557447,35.1012766 22.4680851,35.1012766 Z"
                id="Path"
              ></path>
              <path
                d="M14.6246809,8.41021277 C16.866383,8.41021277 18.6944681,6.58723404 18.6944681,4.34042553 C18.6944681,2.0987234 16.8714894,0.270638298 14.6246809,0.270638298 C12.3778723,0.270638298 10.5548936,2.09361702 10.5548936,4.34042553 C10.5548936,6.58212766 12.3778723,8.41021277 14.6246809,8.41021277 Z M14.6246809,1.80255319 C16.0238298,1.80255319 17.1625532,2.9412766 17.1625532,4.34042553 C17.1625532,5.73957447 16.0238298,6.87829787 14.6246809,6.87829787 C13.2255319,6.87829787 12.0868085,5.73957447 12.0868085,4.34042553 C12.0868085,2.9412766 13.2255319,1.80255319 14.6246809,1.80255319 Z"
                id="Shape"
              ></path>
              <path
                d="M19.4144681,16.7489362 L19.4144681,22.2842553 C19.4144681,22.7080851 19.7565957,23.0502128 20.1804255,23.0502128 C20.6042553,23.0502128 20.946383,22.7080851 20.946383,22.2842553 L20.946383,16.7489362 C20.946383,13.2612766 18.107234,10.427234 14.6246809,10.427234 C11.1421277,10.427234 8.29787234,13.2612766 8.29787234,16.7489362 L8.29787234,22.2842553 C8.29787234,22.7080851 8.64,23.0502128 9.06382979,23.0502128 C9.48765957,23.0502128 9.82978723,22.7080851 9.82978723,22.2842553 L9.82978723,16.7489362 C9.82978723,14.1089362 11.9795745,11.9591489 14.6195745,11.9591489 C17.2595745,11.9591489 19.4144681,14.1038298 19.4144681,16.7489362 Z"
                id="Path"
              ></path>
              <path
                d="M30.3114894,8.41021277 C32.5531915,8.41021277 34.3812766,6.58723404 34.3812766,4.34042553 C34.3812766,2.0987234 32.5582979,0.270638298 30.3114894,0.270638298 C28.0646809,0.270638298 26.2468085,2.09361702 26.2468085,4.34042553 C26.2468085,6.58212766 28.0697872,8.41021277 30.3114894,8.41021277 Z M30.3114894,1.80255319 C31.7106383,1.80255319 32.8493617,2.9412766 32.8493617,4.34042553 C32.8493617,5.73957447 31.7106383,6.87829787 30.3114894,6.87829787 C28.9123404,6.87829787 27.773617,5.73957447 27.773617,4.34042553 C27.7787234,2.9412766 28.9123404,1.80255319 30.3114894,1.80255319 Z"
                id="Shape"
              ></path>
              <path
                d="M35.106383,16.7489362 L35.106383,22.2842553 C35.106383,22.7080851 35.4485106,23.0502128 35.8723404,23.0502128 C36.2961702,23.0502128 36.6382979,22.7080851 36.6382979,22.2842553 L36.6382979,16.7489362 C36.6382979,13.2612766 33.7991489,10.427234 30.3165957,10.427234 C26.8340426,10.427234 23.9897872,13.2612766 23.9897872,16.7489362 L23.9897872,22.2842553 C23.9897872,22.7080851 24.3319149,23.0502128 24.7557447,23.0502128 C25.1795745,23.0502128 25.5217021,22.7080851 25.5217021,22.2842553 L25.5217021,16.7489362 C25.5217021,14.1089362 27.6714894,11.9591489 30.3114894,11.9591489 C32.9514894,11.9591489 35.106383,14.1038298 35.106383,16.7489362 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
