// @flow

import type { ComponentType, Node } from 'react'
import React from 'react'

import styled from '@emotion/styled'
import { media, colors } from '@toggl/style'

export default function Links({
  children,
  setContentKey,
  icon,
  contentKey,
  ...props
}: {
  children: Node,
  setContentKey: (value: boolean) => void,
  icon?: Node,
  contentKey?: string,
}) {
  return (
    <StyledLinks {...props}>
      {React.Children.map(children, (a, index) => (
        <ExpandableLi
          key={index}
          onClick={
            a.props['data-expandable'] === 'true'
              ? () => setContentKey(a.props['data-content-key'])
              : null
          }
        >
          <BorderBottom active={a.props['data-content-key'] === contentKey}>
            {a}
          </BorderBottom>{' '}
          {a.props['data-expandable'] === 'true' ? icon : null}
        </ExpandableLi>
      ))}
    </StyledLinks>
  )
}
const BorderBottom = styled.div`
  ${media.mq[1]} {
    border-bottom: 1px solid
      ${(p) => (p.active ? colors.darkPurple : 'transparent')};
  }
`

const StyledLinks: ComponentType<any> = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin: 0;

  ${media.mq[1]} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & > li {
      margin-bottom: 0;
    }
    min-width: 500px;
  }
`

const ExpandableLi = styled.li`
  display: flex;

  ${media.mq[1]} {
    align-items: center;
  }
`
