// @flow

const breakpoints = [320, 800, 1200, 1440]

export const mq: Array<string> = breakpoints.map(
  (bp) => `@media (min-width: ${bp}px)`
)

export const mqbp: Array<string> = breakpoints.map(
  (bp) => `(min-width: ${bp}px)`
)
