// @flow
import { useEffect } from 'react'

type HookParams = {|
  +ids: $ReadOnlyArray<string>,
  +onIntersect: (string) => void,
  +threshold?: number | $ReadOnlyArray<number>,
|}

export const useIntersect = ({
  ids,
  onIntersect,
  threshold = 0,
}: HookParams) => {
  useEffect(() => {
    const observer = new window.IntersectionObserver(
      (entries) => {
        entries.forEach((element) => {
          if (element.isIntersecting) {
            onIntersect(element.target.id)
          }
        })
      },
      {
        threshold,
      }
    )

    ids.forEach((id) => {
      const element = document.getElementById(id)
      if (element) {
        observer.observe(document.getElementById(id))
      }
    })

    return () =>
      ids.forEach((id) => {
        const element = document.getElementById(id)
        if (element) {
          observer.unobserve(document.getElementById(id))
        }
      })
  })
}
