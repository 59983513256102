// @flow

import type { ComponentType, Node } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { media } from '@toggl/style'
import shapesImg from './corner-shapes.png'

type Props = {
  children: Node,
  background?: string,
}

export default function Large({ children, background }: Props) {
  return (
    <Root background={background}>
      <BgImg src={shapesImg} />
      {children}
    </Root>
  )
}

const BgImg: ComponentType<any> = styled.img`
  width: 160px;
  transform: translateX(20px) translateY(56px);

  ${media.mq[1]} {
    position: absolute;
    width: 260px;
    left: 0;
    transform: translateX(-95px) translateY(-128px);
  }
`

const Root: ComponentType<any> = styled.div`
  display: flex;
  flex-direction: column;
  ${(p) => (p.background ? `background-color: ${p.background};` : '')}

  ${media.mq[1]} {
    position: relative;
    flex-direction: row;
    justify-content: center;
    margin: 140px auto 120px auto;
    max-width: 1200px;
  }
`
