// @flow

import type { ComponentType, Node } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { colors, media } from '@toggl/style'

import pcSrc from './icon-pc.png'
import duckSrc from './icon-duck.png'
import timeSrc from './icon-time.png'
import statsSrc from './icon-stats.png'

type Props = {
  children: Node,
  images?: {|
    topLeft: string,
    topRight: string,
    bottomLeft: string,
    bottomRight: string,
  |},
}

export const CallToAction = ({ children, images }: Props) => {
  return (
    <Root>
      <ImageStyled src={pcSrc} />
      <ContentStyled
        images={
          images || {
            topLeft: duckSrc,
            topRight: timeSrc,
            bottomLeft: statsSrc,
            bottomRight: pcSrc,
          }
        }
      >
        {children}
      </ContentStyled>
    </Root>
  )
}

const ImageStyled = styled.img`
  height: 128px;
  transform: translateY(50px);

  ${media.mq[1]} {
    display: none;
  }
`

export const ContentStyled: ComponentType<any> = styled.div`
  background-color: ${colors.beige};
  padding: 80px 21px 40px 30px;

  ${media.mq[1]} {
    height: 500px;

    ${({ images }) =>
      `background-image: ${images.topLeft && `url(${images.topLeft}),`}
            ${images.topRight && `url(${images.topRight}),`}
            ${images.bottomRight && `url(${images.bottomRight}),`}
            ${images.bottomLeft && `url(${images.bottomLeft})`};

    background-position: ${images.topLeft && 'top 52px left 80px,'}${
        images.topRight && 'top 60px right 355px,'
      }${images.bottomRight && 'bottom 0 right 70px,'}${
        images.bottomLeft && 'bottom 0 left 100px'
      };
    background-size: ${images.topLeft && '450px,'}${
        images.topRight && '100px,'
      }${images.bottomRight && '350px,'}${images.bottomLeft && '650px'};
    background-repeat: no-repeat;`}

    button {
      margin-top: 40px;
    }
  }
`

export const Root: ComponentType<any> = styled.div`
  text-align: center;
`
