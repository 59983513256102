// @flow
import type { Plugin } from '@bugsnag/core'
import { nanoid } from 'nanoid'

const tracer: Plugin = {
  name: '@toggl-tracer',
  load(client) {
    return {
      tracer() {
        const ray = nanoid()
        console.info('About to throw some tracer errors, do not be alarmed.')
        console.info(`Filter for them with: ${ray}`)

        const notifyError = {
          name: 'TracerError',
          message: `Tracer notify ${ray}`,
        }

        client.leaveBreadcrumb('Tracer errors', { id: ray })
        client.notify(notifyError, (event) =>
          event.addMetadata('tracer', {
            id: ray,
          })
        )

        setTimeout(() => {
          const unhandledRejectionError = new Error(
            `Tracer unhandled rejection ${ray}`
          )
          unhandledRejectionError.name = 'TracerUnhandledRejectionError'
          Promise.reject(unhandledRejectionError) // eslint-disable-line promise/catch-or-return
        })

        const uncaughtError = new Error(`Tracer uncaught error ${ray}`)
        uncaughtError.name = 'TracerUncaughtError'
        throw uncaughtError
      },
    }
  },
}

export default tracer
