// @flow

export const yellow = '#FFDE91'
export const pink = '#E57CD8'
export const beige = '#FCE5D8'
export const almostWhite = '#FFF3ED'
export const darkPurple = '#2C1338'
export const fadedPurple = '#412A4C'
export const fadedPurpleAlt = '#564260'
export const dawnPink = '#F3E8E2'
export const almond = '#F0D7CD'
export const violet = '#A876F5'
export const orange = '#FF8A7A'
export const red = '#CC5C4C'
export const lightPink = '#FAE5F7'
