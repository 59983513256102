// @flow

import type { ComponentType } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { colors, lineHeights, media } from '@toggl/style'
import { heading3, heading4, paragraph2, paragraph4 } from '../text'

type Props = {|
  quote: string,
  client: {
    name: string,
    logo: string,
    description: string,
  },
|}

export default function Quote({ client, quote }: Props) {
  return (
    <Root>
      <QuoteStyled>{quote}</QuoteStyled>
      <ClientSection>
        <Logo src={client.logo} />
        <div>
          <Name>{client.name}</Name>
          <Description>{client.description}</Description>
        </div>
      </ClientSection>
    </Root>
  )
}

const Description = styled.p`
  ${paragraph4};
`

const Name: ComponentType<any> = styled.span`
  ${paragraph2};
  line-height: ${lineHeights.paragraph1};
`

const Logo: ComponentType<any> = styled.img`
  width: 55px;
  margin-bottom: 10px;

  ${media.mq[1]} {
    margin-right: 20px;
    margin-bottom: 0;
  }
`

const ClientSection: ComponentType<any> = styled.div`
  margin-top: 25px;

  ${media.mq[1]} {
    margin-top: 31px;
    display: flex;
    align-items: center;
  }
`

const QuoteStyled: ComponentType<any> = styled.div`
  ${heading4};

  ${media.mq[1]} {
    ${heading3};
    line-height: 1.6875;
  }
`

const Root: ComponentType<any> = styled.div`
  width: 320px;
  padding: 56px 18px 54px 30px;
  display: flex;
  flex-direction: column;
  color: ${colors.beige};
  background-color: ${colors.fadedPurpleAlt};

  ${media.mq[1]} {
    padding: 60px 122px;
    width: auto;
    height: 320px;
  }
`
