import { css } from '@emotion/core'
import * as colors from './colors'

import 'modern-normalize'

export default css`
  @font-face {
    font-family: 'GT Haptik';
    font-weight: normal;
    src: url(${require('./fonts/GT-Haptik-Regular.woff')}) format('woff'),
      url(${require('./fonts/GT-Haptik-Regular.woff2')}) format('woff2');
  }
  @font-face {
    font-family: 'GT Haptik Medium';
    font-weight: normal;
    src: url(${require('./fonts/GT-Haptik-Medium.woff')}) format('woff'),
      url(${require('./fonts/GT-Haptik-Medium.woff2')}) format('woff2');
  }

  @font-face {
    font-family: 'GT Haptik Bold';
    font-weight: normal;
    src: url(${require('./fonts/GT-Haptik-Bold.woff')}) format('woff'),
      url(${require('./fonts/GT-Haptik-Bold.woff2')}) format('woff2');
  }

  @font-face {
    font-family: 'GT Haptik Medium Rotalic';
    font-weight: normal;
    src: url(${require('./fonts/GT-Haptik-Medium-Rotalic.woff')}) format('woff'),
      url(${require('./fonts/GT-Haptik-Medium-Rotalic.woff2')}) format('woff2');
  }

  @font-face {
    font-family: 'GT Haptik Bold Rotalic';
    font-weight: normal;
    src: url(${require('./fonts/GT-Haptik-Bold-Rotalic.woff')}) format('woff'),
      url(${require('./fonts/GT-Haptik-Bold-Rotalic.woff2')}) format('woff2');
  }

  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

  html,
  body,
  button {
    font-family: 'GT Haptik Medium', sans-serif;
    font-size: 16px;
  }

  .ReactModal__Content {
    video {
      max-width: 100%;
    }
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    color: ${colors.almostWhite};
    cursor: pointer;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
    color: ${colors.beige};
  }

  em {
    font-style: normal;
  }
`
