// @flow

import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { colors, media } from '@toggl/style'
import { text } from '@toggl/ui'
import type { ComponentType, Element, Node } from 'react'
import { defaultText, paragraph2 } from '../text'

type PropsCheckbox = {
  checked?: boolean,
  color?: string,
  onChange?: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  id: string,
  children?: Element<any>,
  className?: string,
  error?: Node,
}

export const Checkbox = ({
  children,
  id,
  className,
  color,
  error,
  ...props
}: PropsCheckbox) => {
  const [checked, setChecked] = React.useState(props.checked ?? false)

  return (
    <Root className={className}>
      <InputCheckbox
        checked={checked}
        type="checkbox"
        onChange={(event) => {
          event.persist()
          setChecked(event.target.checked)
          props.onChange?.(event)
        }}
        id={id}
        name={id}
      />
      <Label checked={checked} htmlFor={id} color={color}>
        {children}
      </Label>
      {error && <ErrorWrapper>{error}</ErrorWrapper>}
    </Root>
  )
}

const ErrorWrapper = styled.div`
  color: ${colors.red};
  padding-top: 5px;
  ${text.paragraph2};
`

const InputCheckbox = styled.input`
  &[type='checkbox'] {
    display: none;
  }
`

const doohickey = () => css`
  ::after {
    content: '';
    display: flex;
    position: absolute;
    top: 15px;
    ${media.mq[1]} {
      top: 6px;
    }
    left: 11px;
    width: 6px;
    height: 14px;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 30px;

  span {
    color: ${({ color }) => color || colors.almostWhite};
    ${paragraph2};
  }

  &::before {
    content: '';
    -webkit-appearance: none;
    border: 2px solid ${({ color }) => color || colors.almostWhite};
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 20px;
    width: 28px;
    height: 28px;
  }
  ::after {
    border: solid ${(p) => p.color || p.theme.primary};
  }

  ${(p) => (p.checked ? doohickey : '')}
`

const Root: ComponentType<any> = styled.div`
  ${defaultText};
  position: relative;
`
