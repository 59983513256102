// @flow

import type { ComponentType, Node } from 'react'
import React from 'react'
import styled from '@emotion/styled'

import { colors, media } from '@toggl/style'

type Props = {
  children: Node,
  className?: string,
  color?: string,
  backgroundColor?: string,
  skewedTopMargin?: string,
  heightBackground?: string,
  left?: string,
}
export const Skewed = ({
  children,
  className,
  color,
  backgroundColor,
  heightBackground,
  skewedTopMargin,
  left,
}: Props) => {
  return (
    <Root className={className} backgroundColor={backgroundColor}>
      <SkewedColoredBackground
        color={color}
        left={left}
        heightBackground={heightBackground}
        skewedTopMargin={skewedTopMargin}
      />
      <Content>{children}</Content>
    </Root>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 78px 34px 27px 31px;
  ${media.mq[1]} {
    flex-direction: row;
    padding: 190px 0 0 0;
  }
`

export const SkewedColoredBackground: ComponentType<any> = styled.div`
  position: absolute;
  width: 200%;
  height: ${(p) => (p.heightBackground ? p.heightBackground : '120%')};
  left: ${(p) => (p.left ? p.left : '-150px')};
  top: 55px;
  background-color: ${(p) => (p.color ? p.color : colors.beige)};
  transform: rotate(10deg);
  z-index: 90;

  ${media.mq[1]} {
    width: 120vw;
    height: ${(p) => (p.heightBackground ? p.heightBackground : '100%')};
    left: ${(p) => (p.left ? p.left : '-150px')};
    top: ${(p) => (p.skewedTopMargin ? p.skewedTopMargin : '190px')};
  }
`

export const Root: ComponentType<any> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${(p) =>
    p.backgroundColor ? p.backgroundColor : 'transparent'};

  position: relative;
  overflow: hidden;

  > div {
    z-index: 90;
  }
  > div:first-of-type {
    z-index: 90;
  }

  ${media.mq[1]} {
    flex-direction: row;
    justify-content: center;
    padding: 0;
    overflow: hidden;
    position: relative;
  }
`
