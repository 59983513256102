// @flow

import * as React from 'react'
import type { ComponentType } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { colors } from '@toggl/style'

type Props = { color?: string }

export const ArrowBigLeft = ({ color }: Props) => {
  return (
    <svg viewBox="0 0 34 16" version="1.1">
      <g
        id="arrow-left"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Toggl_navigation_mobile_signup"
          transform="translate(-20.000000, -83.000000)"
        >
          <g id="Back" transform="translate(21.000000, 79.000000)">
            <g
              id="Arrow"
              transform="translate(16.500000, 12.000000) scale(-1, 1) translate(-16.500000, -12.000000) translate(0.000000, 6.000000)"
            >
              <rect
                id="Rectangle"
                fill={color || '#412A4C'}
                transform="translate(16.000000, 6.000000) rotate(90.000000) translate(-16.000000, -6.000000) "
                x="15"
                y="-10"
                width="2"
                height="32"
              />
              <path
                d="M26.5,0 L32.3233765,5.82337649 M26.5,12 L32.3233765,6.17662351"
                id="Combined-Shape"
                stroke={color || '#412A4C'}
                strokeWidth="2"
                strokeLinecap="square"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

const arrow = css`
  border: solid;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
`

export const ArrowDown: ComponentType<any> = styled.div`
  ${arrow};

  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
`
export const ArrowUp: ComponentType<any> = styled.div`
  ${arrow};

  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
`

export const ArrowDownThin: ComponentType<any> = styled.div`
  display: inline-block;
  margin-left: 8px;
  margin-bottom: 1px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid ${colors.darkPurple};
`
