// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { colors, media } from '@toggl/style'
import type { ComponentType } from 'react'
import * as text from '../text'

type Props = {|
  image: React.Node,
  title: string,
  paragraph: string,
|}

export function Feature({ image, title, paragraph }: Props) {
  return (
    <Root>
      <ImageWrapper>{image}</ImageWrapper>
      <TextSection>
        <Title>{title}</Title>
        <Paragraph>{paragraph}</Paragraph>
      </TextSection>
    </Root>
  )
}

export const Features: ComponentType<any> = styled.div`
  padding-top: 60px;

  ${media.mq[1]} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 60px;
  }
`
export const FeaturesWrapper: ComponentType<any> = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${(p) => p.theme.primary};
  }

  > h1,
  > h2,
  > h3,
  > h4,
  > h5 {
    text-align: center;
  }
`

const ImageWrapper = styled.div`
  img {
    max-width: 100%;
  }
`

const Paragraph = styled.p`
  ${text.paragraph3};
  color: ${colors.beige};
  margin-top: 23px;

  ${media.mq[1]} {
    margin-top: 8px;
  }
`

const TextSection = styled.div`
  color: ${colors.beige};
  min-height: 200px;
  margin-top: 20px;
  padding-bottom: 25px;
`

const Title = styled.h2`
  color: ${(p) => p.theme.primary};
  display: inline-block;
  border-radius: 16px;

  ${media.mq[1]} {
    background: transparent;

    ${text.heading4};
    color: ${(p) => p.theme.primary};
    line-height: 1.7;
  }
`

const Root: ComponentType<any> = styled.div`
  display: flex;
  flex-direction: column;

  ${media.mq[1]} {
    max-width: 466px;
  }
`
