// @flow

import * as React from 'react'
import type { ComponentType } from 'react'

import styled from '@emotion/styled'
import { text, Icon } from '@toggl/ui'
import { media, colors, lineHeights } from '@toggl/style'
import type { GatsbyLinkProps } from 'gatsby'

const Icons = {
  track: <Icon.Track />,
  hire: <Icon.Hire />,
  plan: <Icon.Plan />,
}

type JobType = {
  title: string,
  position: string,
  description: string,
  team: 'track' | 'hire' | 'plan',
  slug: string,
}

type Props = {
  title: string,
  jobs: Array<JobType>,
  Link: React.ComponentType<GatsbyLinkProps<any>>,
}
export const ThreeCol = ({ title, jobs, Link }: Props) => {
  const count = jobs.length
  const summaryText = `${count} Open vacanc${count === 1 ? 'y' : 'ies'}`

  return (
    <Root>
      <HeaderWrapper>
        <Summary>{summaryText}</Summary>
        <Title>{title}</Title>
      </HeaderWrapper>

      {jobs.map((vacancy, id) => (
        <Link to={`/jobs/${vacancy.slug}`} key={id}>
          <VacancyWrapper>
            <VacancyTitleWrapper>
              <VacancyTitle>{vacancy.title}</VacancyTitle>
              {vacancy.position}
            </VacancyTitleWrapper>

            <VacancyDescription>{vacancy.description}</VacancyDescription>

            <TeamStyled>
              <div>Team:</div>

              <div>{Icons[vacancy.team]}</div>
            </TeamStyled>
          </VacancyWrapper>
        </Link>
      ))}
    </Root>
  )
}

const TeamStyled: ComponentType<any> = styled.div`
  margin-top: 33px;

  div {
    margin-bottom: 5px;
  }
  svg {
    height: 24px;
  }

  ${media.mq[1]} {
    margin-top: 0;
    width: 140px;

    div {
      margin-bottom: 17px;
    }
  }
`

const VacancyDescription: ComponentType<any> = styled.div`
  display: none;

  ${media.mq[1]} {
    display: block;
    width: 467px;
  }
`

const VacancyTitleWrapper: ComponentType<any> = styled.div`
  ${media.mq[1]} {
    width: 311px;
  }
`

const VacancyTitle: ComponentType<any> = styled.div`
  ${text.paragraph2};
  margin-bottom: 7px;

  ${media.mq[1]} {
    ${text.paragraph1};
  }
`

const VacancyWrapper: ComponentType<any> = styled.div`
  ${text.paragraph4}
  display: flex;
  flex-direction: column;
  padding: 35px 20px 36px 31px;
  margin: 10px;
  height: 200px;
  background-color: ${colors.darkPurple};
  color: ${colors.beige};

  ${media.mq[1]} {
    margin: 5px 0;
    padding: 35px 110px 30px 54px;
    height: auto;
    min-height: 160px;
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;

    &:hover {
      background-color: ${colors.fadedPurpleAlt};
    }
  }
`

const HeaderWrapper: ComponentType<any> = styled.div`
  ${text.heading3}
  margin: 0 0 23px 30px;

  ${media.mq[1]} {
    margin-bottom: 45px;
    margin-left: 0;
  }
`

const Title: ComponentType<any> = styled.div`
  ${text.heading3}
  color: ${colors.yellow};
  line-height: ${lineHeights.heading1};

  ${media.mq[1]} {
    display: flex;
  }
`
const Summary: ComponentType<any> = styled.div`
  ${text.heading5}
  color: ${colors.beige};
  margin-bottom: 6px;

  ${media.mq[1]} {
   ${text.paragraph1}
    margin-bottom: 0;
    display: flex;
  }
`

const Root: ComponentType<any> = styled.div`
  display: flex;
  flex-direction: column;
`
