// @flow
import * as React from 'react'
import CountUp from 'react-countup'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { colors, media } from '@toggl/style'
import { Icon, Button, hooks } from '@toggl/ui'

import { heading4, heading5, paragraph4 } from '../text'

type Props = {|
  +buttonText: string,
  +className?: string,
  +expandText: string,
  +features: $ReadOnlyArray<string>,
  +onClick: () => void,
  +price: {|
    +animationDuration?: number,
    +value: string | number,
    +discountValue?: number,
    +currency?: string,
    +details: string | React.Node,
  |},
  +discount?: boolean,
  +subtitle: React.Node,
  +title: string,
  +highlighted?: boolean,
  +highlightedHeader?: string,
|}

export default function Pricing({
  buttonText,
  className,
  expandText,
  features,
  onClick,
  price,
  subtitle,
  title,
  highlighted = false,
  highlightedHeader = '',
  discount,
}: Props) {
  const isDesktop = hooks.useMediaQuery(media.mqbp[1])
  const [expanded, setExpanded] = React.useState(highlighted)

  React.useEffect(() => {
    if (!highlighted) setExpanded(isDesktop)
  }, [isDesktop, setExpanded, highlighted])

  return (
    <div className={className}>
      {highlighted && (
        <HighlightedHeader>
          <div>{highlightedHeader}</div>
        </HighlightedHeader>
      )}
      <Wrapper highlighted={highlighted} expanded={expanded}>
        <Title highlighted={highlighted}>{title}</Title>
        <Subtitle highlighted={highlighted}>{subtitle}</Subtitle>
        <Price highlighted={highlighted} discount={discount} {...price} />
        {!expanded && (
          <ExpandButton caretDirection="down" onClick={() => setExpanded(true)}>
            {expandText}
          </ExpandButton>
        )}
        {expanded && (
          <React.Fragment>
            <Features items={features} highlighted={highlighted} />
            <CtaButton
              text={buttonText}
              highlighted={highlighted}
              onClick={onClick}
            />
          </React.Fragment>
        )}
      </Wrapper>
    </div>
  )
}

const HighlightedHeader: React.ComponentType<any> = styled.div`
  ${heading5};
  height: 50px;
  width: 280px;
  background-color: ${({ theme }) => theme.primary};

  display: flex;
  justify-content: center;
  align-items: center;

  div {
    padding-top: 7px;
  }
`

const Wrapper: React.ComponentType<any> = styled.div`
  display: flex;
  flex-direction: column;

  width: 280px;
  padding: 30px;

  background-color: ${({ highlighted }) =>
    highlighted ? colors.almostWhite : colors.fadedPurple};

  ${media.mq[1]} {
    ${({ expanded }) => (expanded ? 'min-height: 700px;' : '')};
  }

  ${({ highlighted, theme }) =>
    highlighted ? `box-shadow: inset 0 0 0 7px ${theme.primary};` : ''};
`

const Title: React.ComponentType<any> = styled.div`
  ${heading4};
  color: ${({ highlighted, theme }) =>
    highlighted ? theme.primary : theme.accent};

  margin-bottom: 15px;
`

const Subtitle: React.ComponentType<any> = styled.div`
  ${heading5};
  margin-bottom: 25px;
  color: ${({ highlighted, theme }) =>
    highlighted ? colors.darkPurple : theme.accent} !important;
`

const Price = ({
  currency,
  value,
  details,
  discountValue,
  discount,
  highlighted,
  animationDuration,
}) => {
  if (!discountValue || typeof value !== 'number') {
    return (
      <PriceWrapper highlighted={highlighted}>
        <div>{`${currency || ''}${value}`}</div>
        <div>{details}</div>
      </PriceWrapper>
    )
  }

  return (
    <PriceWrapper highlighted={highlighted}>
      <div>
        {`${currency || ''}`}
        {discountValue && (
          <CountUp
            start={discount ? value : discountValue}
            end={discount ? discountValue : value}
            duration={animationDuration || 1}
          />
        )}
        {!discountValue && value}
      </div>
      <div>{details}</div>
    </PriceWrapper>
  )
}

const PriceWrapper: React.ComponentType<any> = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  & > div:first-child {
    ${heading4};
    color: ${({ theme }) => theme.primary};
    font-size: 48px;
    line-height: 54px;
    margin-right: 20px;
  }

  & > div:last-child {
    ${paragraph4};
    font-size: 12px;
    line-height: 18px;
    width: 120px;
    color: ${({ highlighted, theme }) =>
      highlighted ? colors.darkPurple : theme.accent};
  }

  padding-bottom: 25px;
  margin-bottom: ${({ highlighted }) => (highlighted ? '45px' : '25px')};
  border-bottom: 1px solid ${colors.fadedPurpleAlt};
`

const ExpandButton = styled(Button.Secondary)`
  ${paragraph4};

  display: flex;
  justify-content: center;
  cursor: pointer;

  color: ${({ theme }) => theme.accent};
`

const Features = ({ items, highlighted }) => (
  <FeaturesWrapper highlighted={highlighted}>
    {items.map((item, i) => (
      <li key={i}>
        <div>
          <Icon.Check />
        </div>
        {item}
      </li>
    ))}
  </FeaturesWrapper>
)

const FeaturesWrapper: React.ComponentType<any> = styled.ul`
  ${paragraph4};
  color: ${({ highlighted, theme }) =>
    highlighted ? colors.darkPurple : theme.accent};
  list-style: none;
  margin: 0 0 25px 0;
  padding: 0;

  li {
    div {
      position: relative;
      top: 3px;
    }

    display: flex;
    align-items: baseline;
  }

  svg {
    margin-right: 10px;
  }

  li:not(:last-child) {
    margin-bottom: ${({ highlighted }) => (highlighted ? '20px' : '15px')};
  }
`

const ctaStyles = css`
  justify-content: center;

  margin-top: 25px;

  ${media.mq[1]} {
    margin-top: auto;
  }
`

const EnhancedPrimaryButton = styled(Button.Primary)`
  ${ctaStyles};

  margin-top: 45px;
`

const EnhancedAlternateButton = styled(Button.Alternate)`
  ${ctaStyles};
`

const CtaButton = ({ onClick, text, highlighted }) =>
  highlighted ? (
    <EnhancedPrimaryButton fluid onClick={onClick}>
      {text}
    </EnhancedPrimaryButton>
  ) : (
    <EnhancedAlternateButton fluid onClick={onClick}>
      {text}
    </EnhancedAlternateButton>
  )
