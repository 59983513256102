// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import type { ComponentType } from 'react'
import { colors, media } from '@toggl/style'
import * as text from '../text'

const Progress = ({
  active,
  count,
  ...props
}: {
  active: number,
  count: number,
}) => (
  <Root {...props}>
    <Wrapper>
      <Current>0{active + 1} /</Current>
      <OutOf>0{count}</OutOf>
    </Wrapper>
  </Root>
)

const Root: ComponentType<any> = styled.div``

const Wrapper = styled.div`
  margin: 32px auto 0;
  display: flex;
  width: 80%;
  border-top: 1px solid ${colors.beige};
  padding-top: 13px;

  ${media.mq[1]} {
    margin: 65px 0 0;
    width: 100%;
  }
`

const Current = styled.span`
  ${text.paragraph2};
  color: ${colors.beige};
`
const OutOf = styled.span`
  ${text.paragraph2};
  color: ${colors.beige};
  opacity: 0.2;
  padding-left: 4px;
`

export { Progress }
