// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { colors, media } from '@toggl/style'
import type { ComponentType } from 'react'
import { paragraph2, paragraph1 } from '../text'
import { Button } from '../../index'

type Props = {
  image?: React.Node,
  category?: string,
  title: string,
  href?: string,
}

export default function ReadMore({
  image,
  category,
  title,
  href,
  ...props
}: Props) {
  return (
    <Root {...props}>
      {image && <Image>{image}</Image>}
      <TextSection>
        {category && <Category>{category}</Category>}
        <Title>{title}</Title>
        {href && <ButtonReadMore href={href}>Read more</ButtonReadMore>}
      </TextSection>
    </Root>
  )
}

const Image = styled.div`
  img {
    max-width: 100%;
  }
`

const TextSection = styled.div`
  padding: 30px 22px 30px 34px;
  background-color: ${colors.beige};
  color: ${colors.darkPurple};
  display: flex;
  flex-direction: column;
  min-height: 186px;
  button {
    margin-top: 20px;
  }

  ${media.mq[1]} {
    background-color: ${colors.darkPurple};
    color: ${colors.almostWhite};
    padding: 38px 38px 28px 38px;
    min-height: 248px;
  }
`

const ButtonReadMore = styled(Button.Secondary)`
  margin-top: auto;
  color: ${colors.darkPurple};

  ${media.mq[1]} {
    margin-top: auto;
  }
`

const Title = styled.h5`
  ${paragraph2};
  margin-top: 13px;
  min-height: 92px;

  ${media.mq[1]} {
    ${paragraph1};
    margin-top: 16px;
    min-height: 180px;
  }
`

const Root: ComponentType<any> = styled.div`
  display: flex;
  h5,
  p {
    color: ${colors.darkPurple};
  }

  flex-direction: column;

  img {
    max-width: 100%;
  }

  width: 345px;

  ${media.mq[1]} {
    h5 {
      color: ${colors.beige};
    }

    p {
      color: ${(p) => p.theme.primary};
    }
  }
`

const Category = styled.p`
  ${paragraph2};
  color: ${(p) => p.theme.primary};
  min-height: 46px;

  text-transform: capitalize;
  opacity: 0.4;

  ${media.mq[1]} {
    opacity: 1;
    color: ${(p) => p.theme.primary};
  }
`
