// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { colors, media } from '@toggl/style'
import { hooks } from '../../index'
import type { ComponentType } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'

type Props = {
  children: React.Node[],
}

export default function DotMobile({ children }: Props) {
  const [active, setActive] = React.useState(0)
  const isDesktop = hooks.useMediaQuery(media.mqbp[1])
  const settings = {
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    focusOnSelect: true,
    arrows: false,
    centerPadding: '20px',
    beforeChange: (oldIndex, newIndex) => {
      setActive(newIndex)
    },
  }

  return (
    <Root active={active}>
      {!isDesktop && (
        <>
          <Slider {...settings}>
            {React.Children.map(children, (item, index) => {
              return (
                <Slide
                  isFirst={index === 0}
                  isLast={index === React.Children.count(children) - 1}
                >
                  {item}
                </Slide>
              )
            })}
          </Slider>
          <Dots>
            {React.Children.map(children, (item, index) => (
              <Dot active={index === active} onClick={() => setActive(index)} />
            ))}
          </Dots>
        </>
      )}
      {isDesktop && children}
    </Root>
  )
}

const Dot = styled.span`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: ${(p) => (p.active ? colors.beige : colors.fadedPurpleAlt)};
`

const Dots = styled.div`
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  max-width: 10%;

  ${media.mq[1]} {
    display: none;
  }
`

const Slide = styled.div`
  padding: 0 5px;
`

const Root: ComponentType<any> = styled.div`
  max-width: 100%;
  ${media.mq[1]} {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
  }

  .slick-slide.slick-cloned:nth-child(2) {
    opacity: 0;
  }
`
