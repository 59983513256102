// @flow

import type { ComponentType, Node } from 'react'
import React from 'react'

import styled from '@emotion/styled'
import { colors } from '@toggl/style'
import * as text from '../text'

export default function MenuContent({
  title,
  description,
  children,
}: {
  title: string,
  description: string,
  children?: Node,
}) {
  return (
    <StyledMenuHeader>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Content>{children}</Content>
    </StyledMenuHeader>
  )
}

const Content = styled.div`
  padding-top: 40px;
  display: flex;
`

const StyledMenuHeader: ComponentType<any> = styled.div`
  margin-bottom: 40px;

  & > h5 {
    margin-bottom: 8px;
  }

  & > span {
    letter-spacing: 0;
  }
`

const Title = styled.h5`
  ${text.paragraph1};
  color: ${colors.darkPurple};
`
const Description = styled.h5`
  ${text.paragraph3};
  color: ${colors.darkPurple};
`
