// @flow
import * as React from 'react'
import styled from '@emotion/styled'
import { colors, media } from '@toggl/style'
import * as text from '../text'
import type { ComponentType } from 'react'

export default function OverlapImage({
  image,
  alt,
  children,
}: {
  image: string,
  alt: string,
  children: React.Node[],
}) {
  return (
    <Root>
      <ImageWrapper>
        <img src={image} alt={alt} />
      </ImageWrapper>
      <Content>{children}</Content>
    </Root>
  )
}

const Root: ComponentType<any> = styled.div`
  color: ${colors.beige};
  display: flex;
  ${text.paragraph2};

  h3 {
    ${text.heading3};
    color: ${(p) => p.theme.primary};
  }

  ul {
    margin-top: 45px;
    line-height: 1.6;
  }

  span {
    margin-top: 13px;
    display: block;
    ${text.paragraph2};
  }
`
const ImageWrapper = styled.div`
  display: none;
  padding: 50px 0;
  z-index: 30;
  max-width: 25%;
  img {
    width: 100%;
    transform: translateX(15%);
  }

  ${media.mq[1]} {
    display: inherit;
  }
`
const Content = styled.div`
  background: ${colors.darkPurple};
  z-index: 20;
  padding: 30px;
  ul {
    padding: 0;
    margin-left: 20px;
  }
  ${media.mq[1]} {
    padding-left: 18%;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-right: 5%;
    transform: translateX(-15%);
  }
`
