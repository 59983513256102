// @flow
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import { Context as AuthContext, callback } from '@toggl/auth'
import Bugsnag from '@bugsnag/js'
import * as React from 'react'
import {
  connectivity,
  PluginReact,
  tracer,
  uptime,
  verbose,
} from '@toggl/bugsnag'
import pkg from './package'
import ErrorPage from './src/components/ErrorPage'

/*::
declare var process: WebProcess
*/
const environment = process.env.NODE_ENV

Bugsnag.start({
  apiKey: process.env.GATSBY_BUGSNAG_API_KEY,
  appVersion: pkg.version,
  endpoints: {
    notify: process.env.GATSBY_BUGSNAG_NOTIFY_ENDPOINT,
    sessions: process.env.GATSBY_BUGSNAG_SESSION_ENDPOINT,
  },
  enabledReleaseStages: [
    ('development' /*: ReleaseStage */),
    ('production' /*: ReleaseStage */),
  ],
  releaseStage: environment,

  // Disabled due to privacy concerns #6362 (https://git.io/vNClt).
  // If you believe they're now safe, reconsider enabling 💪!
  enabledBreadcrumbTypes:
    environment === 'production'
      ? []
      : [
          'navigation',
          'request',
          'process',
          'log',
          'user',
          'state',
          'error',
          'manual',
        ],

  autoTrackSessions: environment !== 'testing',
  autoDetectErrors: true,
  collectUserIp: false,
  logger: environment === 'production' ? null : verbose.logger,

  metadata: {
    connectivity: {
      interruptions: false,
      offline: false,
    },
  },

  onError: environment === 'production' ? null : verbose.onError,

  plugins: [new PluginReact(React), connectivity, uptime(), tracer],
})

callback.redirectLegacyAuthCallbacks()

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary()

// eslint-disable-next-line react/display-name
export const wrapRootElement = ({ element }: { element: React.Node }) => (
  <ErrorBoundary FallbackComponent={ErrorPage}>
    <AuthContext.Provider>{element}</AuthContext.Provider>
  </ErrorBoundary>
)

window.__tracer = Bugsnag.getPlugin(tracer.name).tracer
