// @flow
import * as React from 'react'

import * as session from './session'

export const context = React.createContext<[boolean, (boolean) => void]>([
  false,
  () => {},
])

export const Provider = (props: { children: React.Node, value?: boolean }) => {
  const [hasSession, setHasSession] = React.useState(
    props.value ?? session.check()
  )
  const sessionContext = [hasSession, setHasSession]

  return (
    <context.Provider value={sessionContext}>{props.children}</context.Provider>
  )
}

export const { Consumer: Consumer } = context
