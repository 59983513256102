// @flow

import * as React from 'react'
import { useTheme } from 'emotion-theming'

type Props = {
  color?: string,
}

export const Check = ({ color, ...props }: Props) => {
  const theme = useTheme()
  return (
    <svg
      width="17px"
      height="14px"
      viewBox="0 0 17 14"
      version="1.1"
      {...props}
    >
      <g
        id="Styleguide"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="UI-guidelines"
          transform="translate(-657.000000, -5850.000000)"
          fill={color ? color : theme.primary}
        >
          <g id="Group-22" transform="translate(107.000000, 5795.000000)">
            <path
              d="M564.913708,55 L567.035029,57.1213203 L555.72132,68.4350288 L555.666,68.38 L555.656854,68.3890873 L550,62.732233 L552.12132,60.6109127 L555.712,64.201 L564.913708,55 Z"
              id="Combined-Shape-Copy-2"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
