// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { colors, media } from '@toggl/style'
import { Hero, Link as UiLink } from '@toggl/ui'

import IndexLayout from '../layouts'
import LayoutGlobal from './LayoutGlobal'
import heroBg from 'images/uploads/homepage-hero-bg.jpg'
import videoSrc from 'images/uploads/bg-video.mp4'
import { Link } from 'gatsby'
import { text } from '@toggl/ui'

declare var process: WebProcess

const ErrorPage = () => {
  const HeroComponent = React.useCallback(({ children }) => {
    return (
      <Hero.Wrapper bgImage={heroBg} bgVideo={videoSrc}>
        {children}

        <StyledWrapper>
          <Hero.Title centered>Error</Hero.Title>
          <SmallTitle>
            Well this is embarrassing, we seem to be having some technical
            difficulties. We'll be back soon.
            <br />
            See our{' '}
            <Link to="https://twitter.com/toggl">
              <UiLink.Underlined>Twitter page</UiLink.Underlined>
            </Link>{' '}
            for updates.
          </SmallTitle>
        </StyledWrapper>
      </Hero.Wrapper>
    )
  }, [])

  return (
    <IndexLayout>
      <LayoutGlobal Hero={HeroComponent}></LayoutGlobal>
    </IndexLayout>
  )
}

const StyledWrapper = styled(Hero.ContentWrapper)`
  ${media.mq[1]} {
    padding: 76px 20px 168px 20px;
  }
`

const SmallTitle = styled.div`
  ${text.paragraph2};
  color: ${colors.almostWhite};
  text-align: center;
  margin-bottom: 15px;

  ${media.mq[1]} {
    margin-bottom: 30px;
  }
`

export default ErrorPage
