// @flow

import type { ComponentType, Node } from 'react'
import React from 'react'

import styled from '@emotion/styled'
import { media, colors } from '@toggl/style'
import { Icon } from '@toggl/ui'
import * as text from '../text'

const icons = {
  track: <Icon.Track dark />,
  hire: <Icon.Hire dark />,
  plan: <Icon.Plan dark />,
}

export function Product({
  img,
  title,
  description,
}: {
  img: string,
  title: Node | string,
  description: string,
}) {
  return (
    <StyledProduct>
      <img src={img} alt={description} />
      <Wrapper>
        <Title>
          {typeof title === 'string' &&
          ['track', 'plan', 'hire'].includes(title)
            ? icons[title]
            : title}
        </Title>
        <Description>{description}</Description>
      </Wrapper>
    </StyledProduct>
  )
}

const Title = styled.div`
  color: ${colors.darkPurple};
  svg {
    width: 120px;
  }
`

const Wrapper = styled.div`
  padding-left: 14px;
  display: flex;
`

const Description = styled.div`
  ${text.paragraph4};
  color: ${colors.darkPurple};
  margin-top: 10px;
`

export const ProductsGroup: ComponentType<any> = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;

  a {
    color: ${colors.darkPurple};
  }

  ${media.mq[1]} {
    flex-direction: row;
  }
`

const StyledProduct: ComponentType<any> = styled.div`
  display: flex;
  margin-bottom: 20px;

  & > img {
    width: 140px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  ${media.mq[1]} {
    display: inline-block;
    width: 280px;

    & > img {
      height: 175px;
      width: 280px;
    }

    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 0;
      margin-top: 16px;

      & > span {
        margin-top: 10px;
      }
    }
  }
`
