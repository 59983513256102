// @flow

import * as React from 'react'

export const PiggyBank = () => (
  <svg viewBox="0 0 34 34" version="1.1">
    <g
      id="Desktop-Track"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Toggl_Business_page"
        transform="translate(-353.000000, -1698.000000)"
        fillRule="nonzero"
      >
        <g id="USP" transform="translate(354.000000, 1410.000000)">
          <g id="USPs" transform="translate(0.000000, 129.000000)">
            <g
              id="noun_savings_2212342"
              transform="translate(0.000000, 160.000000)"
            >
              <path
                d="M2.50859376,18.3482422 L3.60839844,18.3482422 C4.33828124,21.2234375 5.9705078,23.844336 8.2361328,25.7757813 L8.2361328,30.3242188 C8.2361328,31.2482422 8.98789064,32 9.91191408,32 L12.9470703,32 C13.8710938,32 14.6228516,31.2482422 14.6228516,30.3242188 L14.6228516,28.9083984 C16.5710938,29.3158203 18.5923828,29.3158203 20.5402344,28.9083984 L20.5402344,30.3242188 C20.5402344,31.2482422 21.2919922,32 22.2160156,32 L25.2511719,32 C26.1751953,32 26.9269531,31.2482422 26.9269531,30.3242188 L26.9269531,25.7757812 C30.3617188,22.8517578 32.2021484,18.599414 31.9826172,14.0783203 C31.6316406,6.8558594 25.7054688,0.85058596 18.4914063,0.40644532 C16.2888672,0.26992188 14.1503906,0.61972656 12.1341797,1.44394532 C10.4679688,0.3388672 8.68730472,0.046875 7.46777348,-0.00019532 C6.95214848,-0.02109376 6.48554692,0.23359376 6.2214844,0.6785156 C5.95839848,1.12207028 5.95976564,1.67324216 6.22480472,2.11660152 C6.59882816,2.74218744 7.0259766,3.54238276 7.49589848,4.49785152 C5.8246094,6.13457028 4.57441412,8.15234372 3.85996096,10.3652343 L2.50859376,10.3652343 C1.12539064,10.3652344 0,11.490625 0,12.8738281 L0,15.8396484 C0,17.2228516 1.12539064,18.3482422 2.50859376,18.3482422 Z"
                id="Shape"
                stroke="#2C1338"
                strokeWidth="1.5"
              ></path>
              <path
                d="M14.2263672,6.41894532 C16.2482422,5.18691408 18.7605469,5.18652344 20.7832031,6.41894532 C20.8482422,6.45859376 20.9201172,6.47734486 20.9910156,6.47734486 C21.1259765,6.47734486 21.2576172,6.40917968 21.3330078,6.28554688 C21.4478516,6.096875 21.3882812,5.85078124 21.1996094,5.7357422 C18.9201172,4.34707032 16.0890625,4.34707032 13.8099609,5.7357422 C13.621289,5.85078128 13.5617187,6.096875 13.6765625,6.28554688 C13.7916016,6.47402344 14.0375,6.53378908 14.2263672,6.41894532 Z"
                id="Path"
                fill="#2C1338"
              ></path>
              <path
                d="M8.85292968,11.4033203 C9.07382812,11.4033203 9.25292968,11.2242188 9.25292968,11.0033203 C9.25292968,10.2386719 9.875,9.61660156 10.6396484,9.61660156 C11.4044922,9.61660156 12.0265625,10.2386719 12.0265625,11.0033203 C12.0265625,11.2242188 12.2056641,11.4033203 12.4265625,11.4033203 C12.647461,11.4033203 12.8265625,11.2242188 12.8265625,11.0033203 C12.8265625,9.79765624 11.8455078,8.81660156 10.6396484,8.81660156 C9.43398436,8.81660156 8.45292968,9.79765624 8.45292968,11.0033203 C8.45292968,11.2242188 8.63203124,11.4033203 8.85292968,11.4033203 Z"
                id="Path"
                fill="#2C1338"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
