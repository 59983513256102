// @flow

import Bugsnag from '@bugsnag/js'

declare var process: WebProcess

// Redirect olden location states to Track auth.
export const redirectLegacyAuthCallbacks = () => {
  try {
    const url = new URL(window.location)

    console.log('url is', url.href)

    if (!url.searchParams.has('state')) {
      return
    }

    const authUrl = new URL(
      window.location.search,
      process.env.GATSBY_TRACK_AUTH_URL
    ).href

    window.location.assign(authUrl)
  } catch (err) {
    Bugsnag.notify(err)
  }
}
