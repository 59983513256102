// @flow

import { css } from '@emotion/core'
import { fonts, fontSizes, lineHeights, media, colors } from '@toggl/style'
import styled from '@emotion/styled'

export const defaultText = css`
  font-family: ${fonts.gtHaptikMedium};
  font-size: ${fontSizes.defaultText};
`

const paragraph = css`
  font-family: ${fonts.inter};
  font-weight: 400;
  color: ${colors.beige};
`

export const paragraph1 = css`
  ${paragraph};
  font-size: ${fontSizes.paragraph1};
  line-height: ${lineHeights.paragraph1};
`

export const paragraph2 = css`
  ${paragraph};
  font-size: ${fontSizes.paragraph2};
  line-height: ${lineHeights.paragraph2};
`

export const paragraph3 = css`
  ${paragraph};
  font-size: ${fontSizes.paragraph3};
  line-height: ${lineHeights.paragraph3};
`

export const paragraph4 = css`
  ${paragraph};
  font-size: ${fontSizes.paragraph4};
  line-height: ${lineHeights.paragraph4};
`

const heading = css`
  font-family: ${fonts.gtHaptikMedium};
  font-weight: 400;
  color: ${colors.beige};

  em {
    font-family: ${fonts.gtHaptikMediumRotalic};
  }
`

export const heading1 = css`
  ${heading};
  line-height: ${lineHeights.heading1};
  font-size: 2.5rem;

  ${media.mq[1]} {
    font-size: ${fontSizes.heading1};
  }
`

export const heading2 = css`
  ${heading};
  line-height: ${lineHeights.heading1};
  font-size: ${fontSizes.heading3};

  ${media.mq[1]} {
    line-height: ${lineHeights.heading2};
    font-size: ${fontSizes.heading2};
  }
`

export const heading3 = css`
  ${heading};
  font-size: ${fontSizes.heading3};
  line-height: ${lineHeights.heading3};
`

export const heading4 = css`
  ${heading};
  font-size: ${fontSizes.heading4};
  line-height: ${lineHeights.heading4};
`

export const heading5 = css`
  ${heading};
  font-family: 'Inter', sans-serif;
  font-size: ${fontSizes.heading5};
  line-height: ${lineHeights.heading5};
`

export const rotalic = css`
  font-family: ${fonts.gtHaptikMediumRotalic};
`

const StyledH1 = styled.h1`
  ${heading1};
  ${(p) => (p.color ? `color: ${p.color};` : '')}
`

export const H1 = ({
  children,
  ...props
}: {
  children: React.Node,
  color?: string,
}) => {
  return <StyledH1 {...props}>{children}</StyledH1>
}

const StyledH2 = styled.h2`
  ${heading2};
  ${(p) => (p.color ? `color: ${p.color};` : '')}
`

export const H2 = ({
  children,
  ...props
}: {
  children: React.Node,
  color?: string,
}) => {
  return <StyledH2 {...props}>{children}</StyledH2>
}

const StyledH3 = styled.h3`
  ${heading3};
  ${(p) => (p.color ? `color: ${p.color};` : '')}
`

export const H3 = ({
  children,
  ...props
}: {
  children: React.Node,
  color?: string,
}) => {
  return <StyledH3 {...props}>{children}</StyledH3>
}

const StyledH4 = styled.h4`
  ${heading4};
  ${(p) => (p.color ? `color: ${p.color};` : '')}
`

export const H4 = ({
  children,
  ...props
}: {
  children: React.Node,
  color?: string,
}) => {
  return <StyledH4 {...props}>{children}</StyledH4>
}

const StyledH5 = styled.h5`
  ${heading5};
  ${(p) => (p.color ? `color: ${p.color};` : '')}
`

export const H5 = ({
  children,
  ...props
}: {
  children: React.Node,
  color?: string,
}) => {
  return <StyledH5 {...props}>{children}</StyledH5>
}

const StyledP1 = styled.p`
  ${paragraph1};
  ${(p) => (p.color ? `color: ${p.color};` : '')}
`

const StyledP2 = styled.p`
  ${paragraph2};
  ${(p) => (p.color ? `color: ${p.color};` : '')}
`

const StyledP3 = styled.p`
  ${paragraph3};
  ${(p) => (p.color ? `color: ${p.color};` : '')}
`

type PProps = {
  children: React.Node,
  color?: string,
}

export const P1 = ({ children, ...props }: PProps) => {
  return <StyledP1 {...props}>{children}</StyledP1>
}

export const P2 = ({ children, ...props }: PProps) => {
  return <StyledP2 {...props}>{children}</StyledP2>
}

export const P3 = ({ children, ...props }: PProps) => {
  return <StyledP3 {...props}>{children}</StyledP3>
}

export const Wrapper = ({ children, ...props }: PProps) => {
  return <MdxWrapper {...props}>{children}</MdxWrapper>
}

const StyledStrong = styled.b`
  font-weight: 700;
`

export const Strong = ({ children, ...props }: PProps) => {
  return <StyledStrong {...props}>{children}</StyledStrong>
}

const MdxWrapper = styled.div`
  h1 + p,
  h2 + p,
  h3 + p,
  h4 + p,
  h5 + p {
    margin-top: 20px;
  }
`
