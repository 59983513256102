// @flow
import * as React from 'react'
import styled from '@emotion/styled'

import { colors, media } from '@toggl/style'

import * as text from '../text'
import { hooks } from '../../index'

type Props = {|
  +className?: string,
  +title: string,
  +subtitle: string,
  +imgSrc: string,
  +mobileImgSrc: string,
|}

export default function Brands({
  className,
  title,
  subtitle,
  imgSrc,
  mobileImgSrc,
}: Props) {
  const isDesktop = hooks.useMediaQuery(media.mqbp[1])
  return (
    <Wrapper className={className} isDesktop={isDesktop}>
      <Title isDesktop={isDesktop}>{title}</Title>
      {isDesktop && <Subtitle>{subtitle}</Subtitle>}
      <Image src={isDesktop ? imgSrc : mobileImgSrc} />
    </Wrapper>
  )
}

const Wrapper: React.ComponentType<any> = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: center;
  flex-direction: column;
  text-align: center;
  max-width: ${({ isDesktop }) => (isDesktop ? 1000 : 270)}px;
  color: ${colors.darkPurple};
  ${media.mq[1]} {
    padding-top: 100px;
  }
`

const Title: React.ComponentType<any> = styled.div`
  ${text.heading4}
  color: ${colors.darkPurple};
  margin-bottom: ${({ isDesktop }) => (isDesktop ? 15 : 25)}px;

  ${media.mq[1]} {
    ${text.heading3};
    color: ${colors.darkPurple};
  }
`

const Subtitle: React.ComponentType<any> = styled.div`
  ${text.heading4};
  color: ${colors.darkPurple};
  margin-bottom: 60px;

  ${media.mq[1]} {
    ${text.paragraph2};
    color: ${colors.darkPurple};
  }
`

const Image: React.ComponentType<any> = styled.img`
  margin: auto;
  width: 100%;
  object-fit: contain;
`
