// @flow
import * as React from 'react'
import { colors } from '@toggl/style'

export const Twitter = ({
  color,
  height = '16px',
}: {
  color?: string,
  height?: string,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 49 40">
    <path
      fill={color || colors.beige}
      d="M49 4.7a21.6 21.6 0 0 1-5.8 1.6A9.7 9.7 0 0 0 47.6.7a20.1 20.1 0 0 1-6.3 2.5A10 10 0 0 0 33.9 0a9.6 9.6 0 0 0-7.2 3 9.8 9.8 0 0 0-2.9 7.1 10.4 10.4 0 0 0 .3 2.4A28.8 28.8 0 0 1 3.4 2.1 10.2 10.2 0 0 0 2.1 7a9.6 9.6 0 0 0 4.5 8.4A9.1 9.1 0 0 1 2 14.1v.2a9.9 9.9 0 0 0 2.2 6.4 9.8 9.8 0 0 0 5.8 3.4 9.8 9.8 0 0 1-2.7.3l-1.9-.2a10 10 0 0 0 3.5 5 9.7 9.7 0 0 0 5.8 2A19.5 19.5 0 0 1 2.4 36L0 35.9a30.1 30.1 0 0 0 33.5-2.2 31 31 0 0 0 5.7-6.6A28.6 28.6 0 0 0 44 11.3V10a20.5 20.5 0 0 0 5-5.3z"
    />
  </svg>
)

export const Fb = ({
  color,
  height = '16px',
}: {
  color?: string,
  height?: string,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 24 49">
    <path
      fill={color || colors.beige}
      d="M15 49V26.6h8.4l.6-8.2h-9v-6.1c0-2.7.7-4.1 3.5-4.1h5.2L24 .4h-.8A41.4 41.4 0 0 0 18 0c-4 0-7 1.1-9 3.2a11.2 11.2 0 0 0-3 8v7.2H0v8.2h6V49h9z"
    />
  </svg>
)

export const LinkedIn = ({
  color,
  height = '16px',
}: {
  color?: string,
  height?: string,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 47 47">
    <path
      fill={color || colors.beige}
      d="M43.5 0h-40A3.4 3.4 0 0 0 0 3.4v40.2A3.4 3.4 0 0 0 3.5 47h40a3.4 3.4 0 0 0 3.5-3.4V3.4A3.4 3.4 0 0 0 43.5 0zM13.9 40.1H7V17.6h7zm-3.4-25.5a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm29.6 25.5h-7v-11c0-2.6 0-5.9-3.6-5.9S25.3 26 25.3 29v11.1h-7V17.6H25v3.1h.1a7.3 7.3 0 0 1 6.6-3.6c7.1 0 8.4 4.7 8.4 10.7V40z"
    />
  </svg>
)
