// @flow
import styled from '@emotion/styled'
import * as React from 'react'

import { colors, media } from '@toggl/style'

import { heading4, paragraph4 } from '../text'

type Props = {|
  +img: {|
    alt: string,
    src: string,
    width: string,
    height: string,
    marginLeft?: string,
    marginTop?: string,
  |},
  +title: string,
  +details: string,
|}

export default function Workflow({ img, title, details }: Props) {
  return (
    <Wrapper>
      <ImageWrapper>
        <Image {...img} />
      </ImageWrapper>
      <Title>{title}</Title>
      <Details>{details}</Details>
    </Wrapper>
  )
}

const Wrapper: React.ComponentType<any> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 250px;
`

const Title: React.ComponentType<any> = styled.div`
  ${heading4};

  color: ${colors.darkPurple};
  margin-bottom: 15px;
`

const Details: React.ComponentType<any> = styled.div`
  ${paragraph4};

  color: ${colors.fadedPurple};
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 180px;
  width: 180px;
  border-radius: 50%;

  background-color: ${colors.beige};

  margin-bottom: 20px;
  ${media.mq[1]} {
    margin-bottom: 30px;
  }
`

const Image = styled.img`
  object-fit: contain;

  width: ${({ width }) => width};
  height: ${({ height }) => height};

  margin-top: ${({ marginTop }) => marginTop || 0};
  margin-left: ${({ marginLeft }) => marginLeft || 0};
`
