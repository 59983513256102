// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { colors } from '@toggl/style'

type Props = {
  children?: React.Node,
}

const LayoutRoot = ({ children }: Props) => (
  <>
    {/* <Global styles={() => css(normalize)} /> */}
    <StyledLayoutRoot>{children}</StyledLayoutRoot>
  </>
)

const StyledLayoutRoot = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  background-color: ${colors.darkPurple};
`

export default LayoutRoot
