// @flow

import type { ComponentType, Node } from 'react'
import React, { useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'

import { hooks, TableOfContent, Tabs, text } from '@toggl/ui'
import { colors, lineHeights, media } from '@toggl/style'

type SectionType = {
  id: string,
  title: string,
  sections: Array<{
    title: string,
    content: Node,
  }>,
}

type Props = {
  tabs: Array<SectionType>,
}

export const LegalContent = ({ tabs }: Props) => {
  const [activeSection, setActiveSection] = useState(0)
  const [activeTab, onChangeTab] = useState(tabs[0].id)
  const isDesktop = hooks.useMediaQuery(media.mqbp[1])
  const tabData = useMemo(() => tabs.find((tab) => tab.id === activeTab), [
    tabs,
    activeTab,
  ])

  const sectionSubTitles =
    tabData?.sections?.map((section) => section.title) || []

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset
      let setSection = 0

      sectionSubTitles.forEach((sub, i) => {
        const elOffsetTop = document.getElementById(`section_${i}`)?.offsetTop
        if (elOffsetTop < position + 20) {
          setSection = i
        }
      })

      if (setSection !== activeSection) {
        setActiveSection(setSection)
      }
    }

    if (isDesktop) {
      window.addEventListener('scroll', handleScroll, { passive: true })
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }

    return () => {}
  }, [activeSection, isDesktop, sectionSubTitles])

  const onChangeSection = (i) => {
    setActiveSection(i)
    const el = document.getElementById(`section_${i}`)
    if (el) {
      scrollTo(el.offsetTop)
    }
  }

  const scrollTo = (top) => {
    window.scroll({
      top,
      behavior: 'smooth',
    })
  }

  return (
    <Root>
      <NavWrapper>
        <Tabs.Wrapper
          tabs={tabs}
          activeTab={activeTab}
          onChangeTab={onChangeTab}
        />
      </NavWrapper>

      <ContentWrapper>
        <TableOfContentWrapper>
          <TableOfContent.Table>
            {sectionSubTitles?.map((entry, i) => (
              <TableOfContent.Entry
                onClick={() => onChangeSection(i)}
                active={activeSection === i}
                key={`tableOfContent_story_${i}`}
              >
                {entry}
              </TableOfContent.Entry>
            ))}
          </TableOfContent.Table>
        </TableOfContentWrapper>

        <SectionsContentWrapper>
          <Title>{tabData?.title}</Title>

          {tabData?.sections?.map((section, i) => (
            <SectionStyled key={section.title}>
              <SectionTitle id={`section_${i}`}>{section.title}</SectionTitle>
              <SectionContent> {section.content}</SectionContent>
            </SectionStyled>
          ))}
        </SectionsContentWrapper>
      </ContentWrapper>
    </Root>
  )
}

const SectionContent: ComponentType<any> = styled.div`
  ${text.paragraph3};

  color: ${colors.beige};
  line-height: ${lineHeights.heading5};

  ${media.mq[1]} {
    ${text.paragraph4};
    line-height: 1.43;
  }
`
const SectionTitle: ComponentType<any> = styled.div`
  ${text.paragraph1};
  color: ${colors.yellow};
  margin-top: 24px;
  margin-bottom: 19px;

  ${media.mq[1]} {
    margin-bottom: 40px;
  }
`

const SectionStyled: ComponentType<any> = styled.div`
  ${text.heading3}

  ${media.mq[1]} {
    margin-bottom: 45px;
    margin-left: 0;
  }
`

const Title: ComponentType<any> = styled.div`
  ${text.heading3}
    color: ${colors.beige};

  ${media.mq[1]} {
   margin-bottom: 12px;
  }
`

const SectionsContentWrapper = styled.div`
  ${media.mq[1]} {
    margin-left: 47px;
    width: 710px;
  }
`

const TableOfContentWrapper = styled.div`
  display: none;

  ${media.mq[1]} {
    display: block;
    & > div {
      position: sticky;
      top: 0;
    }
  }
`

const ContentWrapper = styled.div`
  display: flex;
  padding: 24px 34px 60px 30px;

  ${media.mq[1]} {
    padding: 54px;
    margin: 0 auto;
  }
`

const NavWrapper = styled.div`
  padding: 16px 10px 0 10px;
  ${media.mq[1]} {
    padding: 0;
  }
`

const Root: ComponentType<any> = styled.div`
  display: flex;
  flex-direction: column;
`
