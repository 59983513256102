// @flow
import * as React from 'react'

import { AuthError, UnexpectedError } from '../errors'
import type { Request, Response } from '../types'

declare var process: WebProcess

type IFrameStatus = 'loading' | 'ready'

const IFRAME_NAME = '@toggl-auth-iframe'
const TIMEOUT = 30 * 1000

export const useIframe = (src: string) => {
  const trustedOrigin = new URL(src).origin

  const [status, setStatus] = React.useState<IFrameStatus>('loading')
  const [iframe, setIFrame] = React.useState<HTMLIFrameElement | null>(null)

  const readyListener = React.useCallback(
    (message: MessageEvent) => {
      if (message.origin !== trustedOrigin) {
        return
      }

      const response = getData(message)

      if (response?.type === 'ready') {
        setStatus('ready')

        document
          .querySelector(`iframe[name="${IFRAME_NAME}"]`)
          ?.setAttribute('data-status', ('ready': IFrameStatus))
        window.removeEventListener('message', readyListener)
      }
    },
    [trustedOrigin]
  )

  React.useEffect(() => {
    let iframe = document.querySelector(`iframe[name="${IFRAME_NAME}"]`)

    if (!iframe) {
      iframe = document.createElement('iframe')
      iframe.name = IFRAME_NAME
      iframe.style.display = 'none'
      iframe.width = '0'
      iframe.height = '0'
      iframe.tabIndex = -1
      iframe.src = src
      iframe.setAttribute('data-status', ('loading': IFrameStatus))
      window.document.body?.appendChild(iframe)

      window.addEventListener('message', readyListener)
    } else {
      if (iframe.getAttribute('data-status') === ('ready': IFrameStatus)) {
        setStatus('ready')
      } else {
        window.addEventListener('message', readyListener)
      }
    }

    // $FlowFixMe[incompatible-call]: Setting `HTMLElement` as `HTMLIFrameElement`.
    setIFrame(iframe)

    return () => {
      window.removeEventListener('message', readyListener)
    }
  }, [readyListener, setIFrame, setStatus, src])

  const send = React.useMemo(
    () => (request: Request): Promise<Response> =>
      new Promise((resolve, reject) => {
        if (status !== 'ready' || !iframe) {
          return reject(new UnexpectedError('IFRAME is not ready yet'))
        }

        // Disallow requests without a `request.id`.
        if (request.type === 'stub') {
          return reject(new UnexpectedError('Stub requests are not supported'))
        }

        const listener = (message: MessageEvent) => {
          if (message.origin !== trustedOrigin) {
            return
          }

          const response = getData(message)

          if (!response || !response.id || response.id !== request.id) {
            return
          }

          if (response.type === 'error') {
            reject(new AuthError(response))
          } else {
            resolve(response)
          }

          window.removeEventListener('message', listener)
        }

        setTimeout(() => {
          window.removeEventListener('message', listener)

          reject(
            new UnexpectedError(
              'Something went wrong. Please try again or contact support.',
              { error: 'timeout', type: request.type }
            )
          )
        }, TIMEOUT)

        window.addEventListener('message', listener)

        iframe.contentWindow.postMessage(JSON.stringify(request), trustedOrigin)
      }),
    [status, iframe, trustedOrigin]
  )

  return [status === ('ready': IFrameStatus), send]
}

// TODO: Might consider throwing an error for incoming requests that do not
// match anything what we'd expect.
const getData = (message): Response | null => {
  try {
    // $FlowFixMe: `message.data` is `mixed`.
    return JSON.parse(message.data)
  } catch (err) {
    return null
  }
}
