// @flow

import * as colors from '../colors'

export const global = {
  primary: colors.yellow,
  accent: colors.darkPurple,
  borderColor: colors.dawnPink,
  background: colors.almostWhite,
}

export const hire: typeof global = {
  primary: colors.orange,
  accent: colors.beige,
  borderColor: colors.dawnPink,
  background: colors.almostWhite,
}

export const plan: typeof global = {
  primary: colors.violet,
  accent: colors.beige,
  borderColor: colors.almond,
  background: colors.beige,
}

export const track: typeof global = {
  primary: colors.pink,
  accent: colors.beige,
  borderColor: colors.almond,
  background: colors.beige,
}
