// @flow
import type { Node } from 'react'
import * as React from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { colors, media } from '@toggl/style'

import { defaultText } from '../text'
import { hooks } from '../../index'
import * as Form from '../form'

type Props = {
  activeTab: string,
  onChangeTab: (tabId: string) => void,
  tabs: $ReadOnlyArray<{
    id: string,
    title: string,
    sections: Array<{
      title: string,
      content: Node,
    }>,
  }>,
}

export function Wrapper({ tabs, activeTab, onChangeTab }: Props) {
  const isDesktop = hooks.useMediaQuery(media.mqbp[1])

  const onTabSelected = (id) => {
    onChangeTab(id)
  }

  if (!isDesktop) {
    return (
      <React.Fragment>
        <Form.Select
          id="test"
          name="test"
          onChange={(evt: SyntheticEvent<>) =>
            onTabSelected((evt.target: window.HTMLInputElement).value)
          }
          label=""
        >
          {tabs.map(({ id, title }) => (
            <option key={id} value={id}>
              {title}
            </option>
          ))}
        </Form.Select>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <WrapperRoot>
        {tabs.map(({ id, title }) => (
          <Tab
            active={id === activeTab}
            id={id}
            key={id}
            title={title}
            onSelected={onTabSelected}
          />
        ))}
      </WrapperRoot>
    </React.Fragment>
  )
}

const WrapperRoot: React.ComponentType<any> = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80px;

  background-color: ${colors.almostWhite};
`

type TabProps = {|
  +active: boolean,
  +id: string,
  +onSelected: (string) => void,
  +title: string,
|}

export function Tab({ active, id, onSelected, title }: TabProps) {
  const isDesktop = hooks.useMediaQuery(media.mqbp[1])
  if (!isDesktop) return <TabRootMobile>{title}</TabRootMobile>
  return (
    <TabRoot active={active} onClick={() => onSelected(id)}>
      <Title active={active}>{title}</Title>
    </TabRoot>
  )
}

const TabRoot: React.ComponentType<any> = styled.div`
  flex: 1 auto;
  height: 100%;
  background-color: ${({ active }) =>
    active ? colors.fadedPurpleAlt : colors.almostWhite};
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: ${({ active }) => (active ? 'default' : 'pointer')};

  &:not(:last-child) {
    border-right: 1px solid ${transparentize(0.9, colors.fadedPurpleAlt)};
  }

  &:hover {
    div {
      ${({ active, theme }) =>
        active ? '' : `border-bottom: 1px solid ${theme.primary}`}
    }
  }
`
// TODO: part of another issue
const TabRootMobile: React.ComponentType<any> = styled.option``

const Title: React.ComponentType<any> = styled.div`
  ${defaultText};
  color: ${({ active }) => (active ? colors.almostWhite : colors.darkPurple)};
  padding-bottom: 2px;
`
