// @flow
import type { ComponentType } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'

import { Icon } from '../../index'
import * as text from '../text'
import { colors, media } from '@toggl/style'

type Props = {
  goBackLink: string,
  title: string,
}
export function BackSocial({ goBackLink, title }: Props) {
  const theme = useTheme()

  return (
    <Root>
      <Header>
        <div>
          <Link href={goBackLink}>
            <Icon.ArrowBigLeft color={theme.primary} />

            <HeaderText>All job openings</HeaderText>
          </Link>
        </div>
        <DesktopSubHeaderContent>
          <SocialIcons />
        </DesktopSubHeaderContent>
      </Header>

      <MobileSubHeaderContent>
        <P>Working at Toggl</P>

        <PositionTitle>{title}</PositionTitle>

        <SocialIcons />
      </MobileSubHeaderContent>
    </Root>
  )
}

const SocialIcons = () => (
  <SocialIconsStyled>
    <P>Share the love</P>

    <div>
      <Link
        target="_blank"
        href={
          'https://twitter.com/home?status=Great%20news!%20@toggl%20is%20hiring%20remotely%20for%20your%20dream%20job!%20Check%20it%20out:%20https://toggl.com/jobs'
        }
      >
        <Icon.Twitter />
      </Link>

      <Link
        target="_blank"
        href={
          'https://www.facebook.com/sharer/sharer.php?u=https://toggl.com/jobs'
        }
      >
        <Icon.Fb />
      </Link>

      <Link
        target="_blank"
        href={
          'https://www.linkedin.com/shareArticle?mini=true&url=https://toggl.com/jobs&title=toggl%20is%20hiring%20remotely%20for%20your%20dream%20job!&summary=&source='
        }
      >
        <Icon.LinkedIn />
      </Link>
    </div>
  </SocialIconsStyled>
)

export const H: ComponentType<any> = styled.h2`
  ${text.heading2};
  color: ${colors.beige};
`

export const P: ComponentType<any> = styled.span`
  ${text.paragraph3};
  color: ${colors.beige};
`

export const Link: ComponentType<any> = styled.a`
  cursor: pointer;

  svg {
    width: 32px;
  }
`

export const PositionTitle: ComponentType<any> = styled(H)`
  width: 249px;
  text-align: center;
  margin-top: 13px;
`

export const SocialIconsStyled: ComponentType<any> = styled.div`
  display: flex;
  flex-direction: column;
  width: 110px;
  margin-top: 20px;

  div {
    display: flex;
    justify-content: space-between;
    margin-top: 13px;
  }

  ${media.mq[1]} {
    flex-direction: row;
    justify-content: space-between;
    width: 244px;
    margin: 0;

    div {
      width: 110px;
      margin: 0;
    }
  }
`

export const DesktopSubHeaderContent: ComponentType<any> = styled.div`
  display: none;

  ${media.mq[1]} {
    display: flex;
  }
`

export const MobileSubHeaderContent: ComponentType<any> = styled.div`
  background-color: ${colors.darkPurple};
  height: 200px;
  padding-top: 26px;

  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.mq[1]} {
    display: none;
  }
`

export const HeaderText: ComponentType<any> = styled(P)`
  margin-left: 20px;
  color: ${colors.beige};
`

export const Header: ComponentType<any> = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 27px 23px;
  border-bottom: 1px solid ${colors.fadedPurple};

  ${media.mq[1]} {
    border-bottom: none;
    padding: 0;
    justify-content: space-between;
  }
`

export const Root: ComponentType<any> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${colors.darkPurple};

  ${media.mq[1]} {
    padding: 64px 122px 49px 122px;
  }
`
