// @flow
import React from 'react'

import styled from '@emotion/styled'
import type { ComponentType, Node, Element } from 'react'
import { defaultText, paragraph2, paragraph3, paragraph4 } from '../text'
import { colors, media } from '@toggl/style'
import { hooks } from '../../index'

type Props = {
  children?: Node,
  logo: Element<any>,
  products: Array<Element<any>>,
  Link: any,
}

export function Wrapper({ children, logo, products, Link }: Props) {
  const isDesktop = hooks.useMediaQuery(media.mqbp[1])
  const hasChildren = !!children
  return (
    <Root>
      <DesktopWrapper hasChildren={hasChildren}>
        <LogoAndList>
          {isDesktop && <LogoWrapper>{logo}</LogoWrapper>}
          <Lists>
            {!isDesktop && <LogoWrapper>{logo}</LogoWrapper>}
            <List title="Toggl Global">
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://toggl.com/blog/"
                >
                  Blog
                </a>
              </li>
              <li>
                <Link to="/mission/">Our Mission</Link>
              </li>
              <li>
                <Link to="/jobs/">Working at Toggl</Link>
              </li>
            </List>
            {children}
          </Lists>
        </LogoAndList>
        <RootProducts hasChildren={hasChildren}>
          <ProductsTitle hasChildren={hasChildren}>
            Discover the Toggl products:
          </ProductsTitle>
          <Logoes hasChildren={hasChildren}>
            {hasChildren
              ? React.Children.map(products, (item) => <Logo>{item}</Logo>)
              : products}
          </Logoes>
        </RootProducts>
      </DesktopWrapper>
      <Bottom>
        <span>&#169; 2020 Toggl, All rights reserved.</span>
      </Bottom>
    </Root>
  )
}

export function List({ title, children }: { title: string, children: Node }) {
  return (
    <RootList>
      <Title>{title}</Title>
      <Ul>{children}</Ul>
    </RootList>
  )
}

const Logo = styled.div`
  display: flex;
  flex-direction: column;
`

const LogoAndList = styled.div`
  ${media.mq[1]} {
    padding: 60px 60px 0 60px;
  }
`

const ProductsTitle = styled.p`
  ${paragraph4};

  ${media.mq[1]} {
    max-width: 120px;
    ${paragraph3};
    margin-right: 120px;
    ${(p) =>
      p.hasChildren
        ? `display: flex;
    align-items: center;`
        : ''}
  }
`

const DesktopWrapper = styled.div`
  ${media.mq[1]} {
    display: flex;
    background: ${colors.darkPurple};
    flex-direction: ${(p) => (p.hasChildren ? 'column' : 'row')};
  }
`

const Root: ComponentType<any> = styled.div`
  color: ${colors.almostWhite};
  z-index: 100;
  position: relative;

  svg {
    max-width: 115px;

    &:nth-child(1) {
      max-width: 125px;
    }
  }
`

const RootProducts: ComponentType<any> = styled.div`
  padding: 40px;
  background: ${(p) =>
    p.hasChildren ? colors.fadedPurple : colors.darkPurple};
  ${ProductsTitle} {
    opacity: 1;
  }
  span {
    ${paragraph4};
    opacity: 0.5;
    line-height: 1.75;
    margin-bottom: 27px;
    ${media.mq[1]} {
      opacity: 1;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ${media.mq[1]} {
    display: flex;
    background: ${colors.darkPurple};
    margin-left: ${(p) => (p.hasChildren ? '0' : '25%')};
    span {
      margin-bottom: ${(p) => (p.hasChildren ? '0' : '27px')};
    }
    height: ${(p) => (p.hasChildren ? '100px' : 'auto')};
    background-color: ${(p) =>
      p.hasChildren ? colors.fadedPurple : colors.darkPurple};
    padding: ${(p) => (p.hasChildren ? '0 60px' : '40px')};
  }
`

const Logoes = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 45px;

  img + span {
    margin-top: 5px;
  }

  ${Logo} {
    &:first-of-type {
      margin-bottom: 30px;

      ${media.mq[1]} {
        margin-bottom: 0;
        margin-right: 50px;
      }
    }
  }

  ${media.mq[1]} {
    margin-top: 0;
    flex-direction: ${(p) => (p.hasChildren ? 'row' : 'column')};
    align-items: ${(p) => (p.hasChildren ? 'center' : 'inheirt')};
  }
`

const Title = styled.span`
  font-family: 'GT Haptik Bold', sans-serif;
  line-height: 2.5;
  display: block;

  ${media.mq[1]} {
    ${paragraph2};
    font-family: 'GT Haptik Bold', sans-serif;
    line-height: 1.7;
  }
`
const RootList: ComponentType<any> = styled.div`
  display: inline-block;
  margin-right: 100px;
  &:last-of-type {
    margin-right: 0;
  }
`

const Bottom = styled.div`
  background: ${(p) => p.theme.primary};
  height: 70px;
  padding: 40px;
  ${paragraph4};
  align-items: center;
  display: flex;
  color: ${colors.darkPurple};

  ${media.mq[1]} {
    padding: 20px 60px;
    height: 52px;
  }
`
const Ul = styled.ul`
  ${paragraph3};
  list-style: none;
  padding: 0;
  margin: 0 0 35px 0;

  li {
    line-height: 2.5;
  }

  ${media.mq[1]} {
    ${paragraph2};
    li {
      line-height: 1.7;
    }
  }
`
const LogoWrapper = styled.div`
  margin-bottom: 35px;
`
const Lists: ComponentType<any> = styled.div`
  ${defaultText};
  position: relative;
  padding: 40px;
  color: ${colors.almostWhite};
  background: ${colors.darkPurple};

  a {
    text-decoration: none;
    transition: color 300ms linear;
    color: ${colors.almostWhite};

    &:hover {
      color: ${(p) => p.theme.primary};
    }
  }

  ${media.mq[1]} {
    padding: 0;
    display: flex;
  }
`
