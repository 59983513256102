// @flow
import * as React from 'react'
import { Progress } from './progress'
import Slider from 'react-slick'
import styled from '@emotion/styled'
import type { ComponentType } from 'react'
import * as text from '../text'
import { colors, media } from '@toggl/style'
import 'slick-carousel/slick/slick.css'

export const ImageGallery = ({ children }: { children: React.Node[] }) => {
  const [active, setActive] = React.useState(0)
  const settings = {
    centerMode: true,
    infinite: false,
    slidesToShow: 1,
    centerPadding: '10%',
    speed: 500,
    focusOnSelect: true,
    arrows: false,
    afterChange: (index) => setActive(index),
  }
  return (
    <Root>
      <Slider {...settings}>
        {React.Children.map(children, (item) => {
          return (
            <Slide>
              <Pic>{item.props.children[0]}</Pic>
              {<Caption>{item.props.children[1]}</Caption>}
            </Slide>
          )
        })}
      </Slider>

      <StyledProgress active={active} count={React.Children.count(children)} />
    </Root>
  )
}
const StyledProgress = styled(Progress)`
  padding: 0 7%;
  ${media.mq[1]} {
    padding: 0 16%;
  }
`
const Root: ComponentType<any> = styled.div``
const Caption = styled.div`
  display: none;

  ${media.mq[1]} {
    ${text.paragraph4};
    color: ${colors.beige};
    margin-top: 13px;
    display: block;
  }
`
const Slide = styled.div`
  padding: 0 20px;
  img {
    max-width: 100%;
  }
  ${media.mq[1]} {
    padding: 0 60px;
    img {
      max-width: 100%;
      margin-right: 30px;
    }
  }
`

const Pic = styled.div``
