// @flow
import * as React from 'react'
import { colors } from '@toggl/style'

export const CaretRight = (props: { classname: string }) => (
  <svg width="10px" height="15px" viewBox="0 0 10 15" version="1.1" {...props}>
    <g
      id="Mobile-Global"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    >
      <g
        id="Toggl_navigation_mobile"
        transform="translate(-291.000000, -106.000000)"
        stroke={colors.fadedPurple}
        strokeWidth="2"
      >
        <g id="Products" transform="translate(18.000000, 97.000000)">
          <path
            d="M275,11 L280.656854,16.6568542 M275,22.6568542 L280.656854,17"
            id="Arrow"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)
