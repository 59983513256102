// @flow
import * as React from 'react'

export const Play = ({
  color,
  arrowColor,
}: {
  color?: string,
  arrowColor?: string,
}) => (
  <svg
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Mobile-Global"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Toggl_job_overview_mobile"
        transform="translate(-128.000000, -1084.000000)"
      >
        <g id="Working-at-Toggl" transform="translate(-20.000000, 405.000000)">
          <g id="vid_thumb" transform="translate(0.000000, 500.000000)">
            <g id="btn-play" transform="translate(148.000000, 179.000000)">
              <ellipse
                id="Oval"
                fill={color || '#FFDE91'}
                cx="31.9643255"
                cy="31.6348593"
                rx="31.9643255"
                ry="31.6348593"
              />
              <polygon
                id="Path"
                fill={arrowColor || '#2C1338'}
                transform="translate(33.296172, 31.634859) rotate(-90.000000) translate(-33.296172, -31.634859) "
                points="30.0008746 28.9711655 33.2961724 34.2985531 36.5914703 28.9711655"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
