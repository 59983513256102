// @flow

import * as React from 'react'

export const Sliders = () => (
  <svg viewBox="0 0 40 40" version="1.1">
    <g
      id="Desktop-Track"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Toggl_Business_page"
        transform="translate(-794.000000, -1615.000000)"
        fill="#2C1338"
        fillRule="nonzero"
      >
        <g id="USP" transform="translate(354.000000, 1410.000000)">
          <g id="USPs" transform="translate(0.000000, 129.000000)">
            <g id="Group" transform="translate(440.000000, 76.000000)">
              <path
                d="M21.4923077,10 L0.507692308,10 C0.22882568,9.99683188 0.00364578064,9.80115416 -1.6305568e-12,9.55882353 L-1.6305568e-12,5.44117647 C0.00364578064,5.19884584 0.22882568,5.00316812 0.507692308,5 L21.4923077,5 C21.7711743,5.00316812 21.9963542,5.19884584 22,5.44117647 L22,9.55882353 C21.9963542,9.80115416 21.7711743,9.99683188 21.4923077,10 Z M1.01538462,9.11764706 L20.9846154,9.11764706 L20.9846154,5.88235294 L1.01538462,5.88235294 L1.01538462,9.11764706 Z"
                id="Shape"
              ></path>
              <path
                d="M15.516129,18 L6.48387097,18 C6.21808899,17.9968319 6.00347472,17.8011542 6,17.5588235 L6,13.4411765 C6.00347472,13.1988458 6.21808899,13.0031681 6.48387097,13 L15.516129,13 C15.781911,13.0031681 15.9965253,13.1988458 16,13.4411765 L16,17.5588235 C15.9965253,17.8011542 15.781911,17.9968319 15.516129,18 L15.516129,18 Z M6.96774194,17.1176471 L15.0322581,17.1176471 L15.0322581,13.8823529 L6.96774194,13.8823529 L6.96774194,17.1176471 Z"
                id="Shape"
              ></path>
              <path
                d="M36.0107479,26.5517241 L11.0237349,26.5517241 C10.7455267,26.548556 10.5208786,26.3528783 10.5172414,26.1105477 L10.5172414,21.9929006 C10.5208786,21.75057 10.7455267,21.5548923 11.0237349,21.5517241 L36.0107479,21.5517241 C36.288956,21.5548923 36.5136042,21.75057 36.5172414,21.9929006 L36.5172414,26.1105477 C36.5136042,26.3528783 36.288956,26.548556 36.0107479,26.5517241 L36.0107479,26.5517241 Z M11.5302284,25.6693712 L35.5042544,25.6693712 L35.5042544,22.4340771 L11.5302284,22.4340771 L11.5302284,25.6693712 Z"
                id="Shape"
              ></path>
              <path
                d="M38.8793103,35.5172414 L26.8793103,35.5172414 C26.604669,35.5140733 26.3829009,35.3183955 26.3793103,35.0760649 L26.3793103,30.9584178 C26.3829009,30.7160872 26.604669,30.5204095 26.8793103,30.5172414 L38.8793103,30.5172414 C39.1539517,30.5204095 39.3757198,30.7160872 39.3793103,30.9584178 L39.3793103,35.0760649 C39.3757198,35.3183955 39.1539517,35.5140733 38.8793103,35.5172414 Z M27.3793103,34.6348884 L38.3793103,34.6348884 L38.3793103,31.3995943 L27.3793103,31.3995943 L27.3793103,34.6348884 Z"
                id="Shape"
              ></path>
              <path
                d="M4.13793103,39.8275862 C3.85381927,39.8238719 3.62440401,39.5944566 3.62068966,39.3103448 L3.62068966,10.3448276 C3.62068966,10.0591631 3.85226651,9.82758621 4.13793103,9.82758621 C4.42359556,9.82758621 4.65517241,10.0591631 4.65517241,10.3448276 L4.65517241,39.3103448 C4.65145806,39.5944566 4.4220428,39.8238719 4.13793103,39.8275862 L4.13793103,39.8275862 Z"
                id="Path"
              ></path>
              <path
                d="M4.13793103,6.03448276 C3.85381927,6.03076841 3.62440401,5.80135315 3.62068966,5.51724138 L3.62068966,0.689655172 C3.62068966,0.403990647 3.85226651,0.172413793 4.13793103,0.172413793 C4.42359556,0.172413793 4.65517241,0.403990647 4.65517241,0.689655172 L4.65517241,5.51724138 C4.65145806,5.80135315 4.4220428,6.03076841 4.13793103,6.03448276 Z"
                id="Path"
              ></path>
              <path
                d="M34.4827586,39.8275862 C34.1986469,39.8238719 33.9692316,39.5944566 33.9655172,39.3103448 L33.9655172,35.862069 C33.9655172,35.5764044 34.1970941,35.3448276 34.4827586,35.3448276 C34.7684231,35.3448276 35,35.5764044 35,35.862069 L35,39.3103448 C34.9962856,39.5944566 34.7668704,39.8238719 34.4827586,39.8275862 L34.4827586,39.8275862 Z"
                id="Path"
              ></path>
              <path
                d="M34.4827586,22.5862069 C34.1986469,22.5824925 33.9692316,22.3530773 33.9655172,22.0689655 L33.9655172,0.689655172 C33.9655172,0.403990647 34.1970941,0.172413793 34.4827586,0.172413793 C34.7684231,0.172413793 35,0.403990647 35,0.689655172 L35,22.0689655 C34.9962856,22.3530773 34.7668704,22.5824925 34.4827586,22.5862069 L34.4827586,22.5862069 Z"
                id="Path"
              ></path>
              <path
                d="M34.4827586,31.5517241 C34.1986469,31.5480098 33.9692316,31.3185945 33.9655172,31.0344828 L33.9655172,26.8965517 C33.9655172,26.6108872 34.1970941,26.3793103 34.4827586,26.3793103 C34.7684231,26.3793103 35,26.6108872 35,26.8965517 L35,31.0344828 C34.9962856,31.3185945 34.7668704,31.5480098 34.4827586,31.5517241 L34.4827586,31.5517241 Z"
                id="Path"
              ></path>
              <path
                d="M24.8275862,39.8275862 C24.5434744,39.8238719 24.3140592,39.5944566 24.3103448,39.3103448 L24.3103448,26.8965517 C24.3103448,26.6108872 24.5419217,26.3793103 24.8275862,26.3793103 C25.1132507,26.3793103 25.3448276,26.6108872 25.3448276,26.8965517 L25.3448276,39.3103448 C25.3411132,39.5944566 25.111698,39.8238719 24.8275862,39.8275862 L24.8275862,39.8275862 Z"
                id="Path"
              ></path>
              <path
                d="M24.8275862,22.5862069 C24.5434744,22.5824925 24.3140592,22.3530773 24.3103448,22.0689655 L24.3103448,0.689655172 C24.3103448,0.403990647 24.5419217,0.172413793 24.8275862,0.172413793 C25.1132507,0.172413793 25.3448276,0.403990647 25.3448276,0.689655172 L25.3448276,22.0689655 C25.3411132,22.3530773 25.111698,22.5824925 24.8275862,22.5862069 Z"
                id="Path"
              ></path>
              <path
                d="M14.4827586,39.8275862 C14.1986469,39.8238719 13.9692316,39.5944566 13.9655172,39.3103448 L13.9655172,26.8965517 C13.9655172,26.6108872 14.1970941,26.3793104 14.4827586,26.3793104 C14.7684231,26.3793104 15,26.6108872 15,26.8965517 L15,39.3103448 C14.9962856,39.5944566 14.7668704,39.8238719 14.4827586,39.8275862 L14.4827586,39.8275862 Z"
                id="Path"
              ></path>
              <path
                d="M14.5172414,13.4827586 C14.2331296,13.4790442 14.0037144,13.249629 14,12.9655172 L14,9.51724135 C14,9.23157683 14.2315769,9 14.5172414,9 C14.8029059,9 15.0344828,9.23157683 15.0344828,9.51724135 L15.0344828,12.9655172 C15.0307684,13.249629 14.8013531,13.4790442 14.5172414,13.4827586 L14.5172414,13.4827586 Z"
                id="Path"
              ></path>
              <path
                d="M14.4827586,6.03448276 C14.1986469,6.03076841 13.9692316,5.80135315 13.9655172,5.51724138 L13.9655172,0.689655172 C13.9655172,0.403990659 14.1970941,0.172413824 14.4827586,0.172413824 C14.7684231,0.172413824 15,0.403990659 15,0.689655172 L15,5.51724138 C14.9962856,5.80135315 14.7668704,6.03076841 14.4827586,6.03448276 L14.4827586,6.03448276 Z"
                id="Path"
              ></path>
              <path
                d="M14.5172414,21.5862069 C14.2331296,21.5824925 14.0037144,21.3530773 14,21.0689655 L14,17.6206897 C14,17.3350251 14.2315769,17.1034483 14.5172414,17.1034483 C14.8029059,17.1034483 15.0344828,17.3350251 15.0344828,17.6206897 L15.0344828,21.0689655 C15.0307684,21.3530773 14.8013531,21.5824925 14.5172414,21.5862069 L14.5172414,21.5862069 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
