// @flow

import React from 'react'
import styled from '@emotion/styled'
import type { ComponentType } from 'react'

type Props = {
  children?: Node | string,
}

export const Underlined = ({ children, ...props }: Props) => {
  return <Root {...props}>{children}</Root>
}

const Root: ComponentType<any> = styled.span`
  border-bottom: 1px solid ${(p) => p.theme.primary};
  display: inline-block;
`
