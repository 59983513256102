// @flow
import * as React from 'react'
import ReactSlider from 'react-slider'
import styled from '@emotion/styled'

import { colors, media } from '@toggl/style'
import { text } from '@toggl/ui'
import { transparentize } from 'polished'
import { LabelWrapper } from '../form'
type Props = {|
  +className?: string,
  +onChange: (number) => void,
  +values: $ReadOnlyArray<string>,
  label?: React.Node,
  error?: React.Node,
|}

export default function Slider({
  className,
  onChange,
  values,
  label,
  error,
}: Props) {
  const [min, max] = React.useMemo(() => {
    return [0, values.length - 1]
  }, [values])

  const getValueLabel = ({ valueNow }) => {
    return values[valueNow]
  }

  const onAfterChange = (value) => {
    onChange(values[value])
  }

  return (
    <Wrapper className={className}>
      <LabelWrapper label={label} error={error}>
        <StyledSlider
          ariaValuetext={getValueLabel}
          max={max}
          min={min}
          onAfterChange={onAfterChange}
          renderThumb={Thumb}
          renderTrack={Track}
        />
      </LabelWrapper>
    </Wrapper>
  )
}

const Wrapper: React.ComponentType<any> = styled.div`
  padding-top: 24px;
`

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 2px;
  margin-top: 40px;
`

const Caret = styled.span`
  width: 0;
  height: 0;
  display: inline-block;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;

  ${media.mq[1]} {
    border-top-width: 4px;
    border-bottom-width: 4px;
  }
`

const CaretLeft = styled(Caret)`
  border-right: 2px solid ${colors.darkPurple};
  margin-left: 9px;

  ${media.mq[1]} {
    margin-left: 18px;
    border-right-width: 4px;
  }
`

const CaretRight = styled(Caret)`
  border-left: 2px solid ${colors.darkPurple};
  margin-right: 9px;

  ${media.mq[1]} {
    margin-right: 18px;
    border-left-width: 4px;
  }
`

const ThumbWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 32px;
  width: 92px;
  top: -16px;

  ${media.mq[1]} {
    width: 128px;
    height: 48px;
    top: -24px;
  }

  outline: none;
  border-radius: 24px;
  cursor: grab;

  background-color: ${({ theme }) => theme.accent};
  color: ${colors.darkPurple};

  &:hover,
  &:active {
    background-color: ${colors.fadedPurpleAlt};
    color: ${({ theme }) => theme.accent};

    ${CaretLeft} {
      border-right-color: ${({ theme }) => theme.accent};
    }

    ${CaretRight} {
      border-left-color: ${({ theme }) => theme.accent};
    }
  }
`

const ThumbText = styled.span`
  ${text.paragraph4};
  margin: 0 auto;
  padding-bottom: 1px;
  color: ${colors.darkPurple};

  ${media.mq[1]} {
    ${text.paragraph3};
    color: ${colors.darkPurple};
  }
`

const Thumb = (props) => (
  <ThumbWrapper {...props}>
    <CaretLeft />
    <ThumbText>{props['aria-valuetext']}</ThumbText>
    <CaretRight />
  </ThumbWrapper>
)

const TrackWrapper = styled.div`
  top: 0;
  bottom: 0;
  background-color: ${({ theme }) => transparentize(0.8, theme.accent)};
`

const Track = (props, state) => <TrackWrapper {...props} index={state.index} />
